@use "../../../../resources/scss/utils/mixins";
@use "../../../../resources/scss/themes/themes";

.table-cell-animal-amount {
  display: flex;
  justify-content: space-between;

  > :first-child {
    @include mixins.noBreakWord();
  }

  > :last-child {
    flex: 0 0 2rem;
    text-align: center;
    padding-left: .5rem;

    @include themes.themify(themes.$themes) {
      border-left: 1px solid themes.themed("background500");
    }
  }
}