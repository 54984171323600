@use "../../../../resources/scss/themes/themes";
@use "../../../../resources/scss/utils/mixins";
.fetura-farm-map {
  &__ctrl-container {
    position: absolute;
    left: .75rem;
    top: .75rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--mobile {
      top: 4rem;
      flex-direction: row-reverse;
      @media screen and (orientation: landscape) {
        width: 50%;
        gap: 1rem;
        left: unset;
        right: .75rem;
        top: 0.5rem;
      }
    }
  }
  &__ctrl-animals-container {
    position: absolute;
    bottom: 0;
    left: 0.75rem;
    min-width: 200px;
    background: #2d2d2d;
    color: #DCDDDE;
    border: 1px solid #242424;
    border-radius: 0.5rem;
    transition: transform 500ms ease-in-out;
    transform: translateY(calc(100%));
    z-index:3;
    pointer-events: none;
    &-top{
      position: absolute;
      left: .75rem;
      bottom: 0;
      top: -3rem;
      height:3rem;
      display: flex;
      cursor:pointer;
      flex-direction: column;
      width: auto;
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
      justify-content: flex-start;
      align-items: center;
      padding:0.5rem 1rem 0.5rem 1rem;
      border-radius: 0.5rem 0.5rem 0 0;
     pointer-events: auto;
      border: 1px solid #242424;
      @include themes.themify(themes.$themes) {
        background: themes.themed("info500");
        color: themes.textContrast(themes.themed("info500"));
      }
    }
    .animals-content{
      padding: 0.5rem 1rem;
      height: 200px;
      overflow-y: scroll;
      pointer-events: auto;
      user-select: none;
      &::-webkit-scrollbar {
        width: 0.2rem;
      }
       
       
      &::-webkit-scrollbar-thumb {
        background-color: #fff;
        outline: 1px solid #ffffff;
        border-radius: 1rem;
      }
    }
  }
  &__ctrl-animals-container.expanded{
    transform:  translateY(-0.5rem);
  }
}