@use "../../../src/resources/scss/themes/_themes";

.technical-break-banner {
  font-weight: 600;
  height: 2rem;
  min-height: 2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @include themes.themify(themes.$themes) {
    background: themes.themed("warning700");
    color: themes.textContrast(themes.themed("warning700"));
  }

  &.mobile {
    @media only screen and (max-width: 420px) {
      align-items: flex-end;
      text-align: right;
      font-size: .7rem;
      padding: 0 .7rem 0 4rem;
    }

    @media only screen and (min-width: 421px) {
      align-items: flex-end;
      text-align: right;
      font-size: .7rem;
      padding: 0 .7rem 0 4rem;
    }
  }
}