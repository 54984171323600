@use "../../../../resources/scss/themes/themes";

.fetura-grid {
  @include themes.themify(themes.$themes) {
    .icon-component {
      color: themes.themed('fontColorDark');
      cursor: pointer;
      transition: transform 200ms ease-in-out;

      &:not(.disabled):hover {
        transform: scale(1.3);
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.38;
      }
    }
  }
}