//.printable {
//  display: none;
//}

@page card {
  size: landscape;
}

@media print {
  $headerSize: 6rem;

  html {
    font-size: 12px;
  }

  #root {
    display: flex;

    > *:not(.printable) {
      display: none;
    }
  }

  #recharts_measurement_span {
    display: none;
  }

  .printable {
    .sow-card-print {
      display: flex;

      table {
        height: 100%;
        width: 100%;

        .table-header {
          display: flex;
          height: $headerSize;
          justify-content: space-between;
          align-items: center;

          .logo {
            width: auto;
            height: 100%;
          }

          > div {
            display: flex;

            .qr-container {
              width: $headerSize;
              height: $headerSize;
              border: 1px solid black;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 1rem;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        td {
          float: none;
        }
      }

      .basic-info-box-edit, .non-print-first-record, .print-display-none, .box, .fa-info-circle {
        display: none !important;
      }

      .sow-card {
        margin-top: 5rem;
        .cycle-grid-content {
          border-bottom: 1px solid #747474 !important;
      }
      
      }
      .sow-results-container{
        page-break-before: always;
        margin-top: 3rem;
        .fetura-grid{
          display:none !important;
        }
        .gauge-item > svg > text.visible {
          opacity: 0 !important;
        }
      }
      #sow-results{
        display:flex;
        flex-direction:row;
        
      }
      .empty-parturition {
        display: block;
        width: 6rem;
        border: 1px solid black;
      }

      .print-last {
        border-right: none !important;
      }
    }
  }
}

.sow-card-page {
  page: card;
}
