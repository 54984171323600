@use "../../resources/scss/themes/themes";

.compare-insertion-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;

  ul {
    padding: 0;

    li {
      padding-top: 4px;
      padding-bottom: 4px;

      @include themes.themify(themes.$themes) {
        &.success {
          color: themes.themed("success500");
        }

        &.error {
          color: themes.themed("error500");
        }
      }
    }
  }

  img {
    width: 10rem;
    height: 10rem;
  }
}