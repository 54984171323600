@use "../../../../resources/scss/themes/themes";

.fetura-grid-mobile-row {
  padding: themes.$paddingBigVertical 0;
  position: relative;
  z-index: 0;

  &:not(:last-child) {
    @include themes.themify(themes.$themes) {
      border-bottom: 1px solid themes.themed("secondary500");
    }
  }

  &__content {
    z-index: 1;
  }


  @include themes.themify(themes.$themes) {
    &.selected {
      &:before {
        position: absolute;
        content: ' ';
        left: -.75rem;
        top: 0;
        width: calc(100% + 1.5rem);
        height: 100%;
        border-radius: 0.5rem;
        $bgColor: themes.themed('success500');
        $selectedColor: mix($bgColor, themes.themed('background50'), 50%);
        background: $selectedColor !important;
        color: themes.textContrast($selectedColor);
        pointer-events: none;
        z-index: -1;
      }
    }
  }

  &-date {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
  }

  &-select {
    text-align: right;

    .fetura-checkbox {
      margin: 0 !important;
    }

    .fetura-radio {
      margin: 0 !important;
    }
  }

  &-floating {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.5rem;
    z-index: 1;

    >* {
      margin-left: 0.7rem;
      z-index: 1;
    }
  }
}