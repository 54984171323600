@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";


.fetura-placeholder {
  @include themes.themify(themes.$themes) {
    $color: #{themes.textContrast(themes.themed("background300"))}0F;
    $background: themes.themed("background300");
    background: $background;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    >div {
      border-radius: 0.25rem;
      width: 100%;
      height: 1rem;
      margin-bottom: 0.5rem;
      background: $color;
    }

    &.type-text {
      display: flex;
      flex-direction: column;

      >div {
        height: inherit;
      }
    }

    &.type-standing {
      display: grid;
      padding: 0.5rem;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: repeat(7, 1fr);
      grid-column-gap: 0.25rem;
      grid-row-gap: 0.25rem;

      >div {
        height: 100%;
      }

      .ph-0 {
        grid-area: 1 / 20 / 2 / 24;
      }

      .ph-1 {
        grid-area: 2 / 1 / 3 / 4;
      }

      .ph-2 {
        grid-area: 3 / 1 / 4 / 6;
      }

      .ph-3 {
        grid-area: 4 / 1 / 5 / 8;
      }

      .ph-4 {
        grid-area: 6 / 1 / 7 / 11;
      }

      .ph-5 {
        grid-area: 7 / 9 / 8 / 14;
      }

      .ph-6 {
        grid-area: 2 / 9 / 4 / 13;
      }

      .ph-7 {
        grid-area: 4 / 9 / 5 / 13;
      }

      .ph-8 {
        grid-area: 2 / 14 / 3 / 17;
      }

      .ph-9 {
        grid-area: 3 / 14 / 4 / 17;
      }

      .ph-10 {
        grid-area: 4 / 14 / 5 / 16;
      }

      .ph-11 {
        grid-area: 3 / 19 / 6 / 20;
      }

      .ph-12 {
        grid-area: 4 / 20 / 6 / 21;
      }

      .ph-13 {
        grid-area: 3 / 21 / 6 / 22;
      }

      .ph-14 {
        grid-area: 4 / 22 / 6 / 23;
      }

      .ph-15 {
        grid-area: 3 / 23 / 6 / 24;
      }

      .ph-16 {
        grid-area: 1 / 24 / 2 / 25;
      }
    }

    &.type-feeding-simple {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(8, 1fr);
      grid-column-gap: 0.25rem;
      grid-row-gap: 0.25rem;

      >div {
        height: 100%;
        width: 100%;
      }

      .ph-0 {
        grid-area: 1 / 1 / 2 / 8;
      }

      .ph-1 {
        grid-area: 3 / 1 / 4 / 2;
      }

      .ph-2 {
        grid-area: 4 / 1 / 5 / 2;
      }

      .ph-3 {
        grid-area: 5 / 1 / 6 / 2;
      }

      .ph-4 {
        grid-area: 6 / 1 / 7 / 2;
      }

      .ph-5 {
        grid-area: 7 / 1 / 8 / 2;
      }

      .ph-6 {
        grid-area: 8 / 1 / 9 / 2;
      }

      .ph-7 {
        grid-area: 3 / 2 / 4 / 3;
      }

      .ph-8 {
        grid-area: 4 / 2 / 5 / 3;
      }

      .ph-9 {
        grid-area: 5 / 2 / 6 / 3;
      }

      .ph-10 {
        grid-area: 6 / 2 / 7 / 3;
      }

      .ph-11 {
        grid-area: 7 / 2 / 8 / 3;
      }

      .ph-12 {
        grid-area: 8 / 2 / 9 / 3;
      }

      .ph-13 {
        grid-area: 3 / 3 / 4 / 4;
      }

      .ph-14 {
        grid-area: 4 / 3 / 5 / 4;
      }

      .ph-15 {
        grid-area: 5 / 3 / 6 / 4;
      }

      .ph-16 {
        grid-area: 6 / 3 / 7 / 4;
      }

      .ph-17 {
        grid-area: 7 / 3 / 8 / 4;
      }

      .ph-18 {
        grid-area: 8 / 3 / 9 / 4;
      }

      .ph-19 {
        grid-area: 3 / 4 / 4 / 5;
      }

      .ph-20 {
        grid-area: 4 / 4 / 5 / 5;
      }

      .ph-21 {
        grid-area: 5 / 4 / 6 / 5;
      }

      .ph-22 {
        grid-area: 6 / 4 / 7 / 5;
      }

      .ph-23 {
        grid-area: 7 / 4 / 8 / 5;
      }

      .ph-24 {
        grid-area: 8 / 4 / 9 / 5;
      }
    }

    &.type-settings-main-view {
      display: flex;
      flex-direction: column;

      // keyframes are global, add theme name so they won't get overwritten
      @keyframes color-pulse-2-#{themes.getThemeName()} {
        0% {
          background-color: themes.themed("background100");
        }

        50% {
          background-color: themes.themed("background200");
        }

        100% {
          background-color: themes.themed("background100");
        }
      }


      >div {
        height: 100%;
        width: 100%;
        animation-name: color-pulse-2-#{themes.getThemeName()};
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        min-height: 3rem;
        border-radius: themes.$radiusBig;
        margin-bottom: 1rem;
      }

      .ph-0 {
        margin-top: 3rem;
      }
    }

    &.type-table {
      display: grid;
      padding: 0.5rem;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(11, 1fr);
      grid-column-gap: 2px;
      grid-row-gap: 2px;
      .ph-0 {
        grid-area: 1 / 1 / 2 / 6;
      }
      > div  {
        height: 2rem;
        border-radius: 5px;
      }
    }
    &.type-chart {
      display: block;
      position: relative;

      >div {
        position: absolute;
      }

      $left: 75px;
      $bottom: 50px;
      $labelSizeW: 25px;
      $labelSizeH: .75rem;

      .ph-0 {
        left: $left;
        top: $bottom - 10px;
        width: calc(100% - #{2*$left});
        height: calc(100% - #{2*$bottom});
      }

      .ph-1,
      .ph-2,
      .ph-3 {
        left: $left;
        width: $labelSizeW;
        height: $labelSizeH;
        transform: translateX(calc(-100% - 5px));
      }

      .ph-1 {
        top: calc(25% - #{$bottom});
      }

      .ph-2 {
        top: calc(50% - #{$bottom});
      }

      .ph-3 {
        top: calc(75% - #{$bottom});
      }

      .ph-4,
      .ph-5,
      .ph-6 {
        bottom: $bottom;
        width: $labelSizeW;
        height: $labelSizeH;
        transform: translateY(calc(100% + 5px));

      }

      .ph-4 {
        left: calc(25% - #{$left});
      }

      .ph-5 {
        left: calc(50% - #{$left});
      }

      .ph-6 {
        left: calc(75% - #{$left});
      }

      .ph-7 {
        left: $left;
        width: $labelSizeH;
        height: $labelSizeW * 3;
        transform: translateX(calc(-100% - #{$labelSizeW} + .75rem));
        top: calc(50% - #{$bottom});
      }

      .ph-8 {
        bottom: $bottom;
        width: $labelSizeW * 3;
        height: $labelSizeH;
        transform: translateY(calc(100% + #{$labelSizeW} + .75rem));
        left: calc(50% - #{$left + $labelSizeW });
      }
    }
  }
}