@use "../../../resources/scss/utils/mixins";
@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/themes/normal-theme";

.tooltip {
  position: absolute;
  pointer-events: none;
  left: -1000px;
  top: -1000px;
  $colorSuccess: map-get(normal-theme.$normalColorTheme, "success500");
  $colorSecondary: map-get(normal-theme.$normalColorTheme, "secondary500");
  $colorWarn: map-get(normal-theme.$normalColorTheme, "warning500");
  $colorError: map-get(normal-theme.$normalColorTheme, "error500");
  $colorInfo: map-get(normal-theme.$normalColorTheme, "info500");

  .tooltip-inner {
    max-width: 300px;
    @include mixins.shadow();
  }

  &.success,
  &.primary {

    &.bs-tooltip-top {
      .tooltip-arrow {
        &:before {
          border-top-color: $colorSuccess;
        }
      }
    }

    &.bs-tooltip-right {
      .tooltip-arrow {
        &:before {
          border-right-color: $colorSuccess;
        }
      }
    }

    &.bs-tooltip-bottom {
      .tooltip-arrow {
        &:before {
          border-bottom-color: $colorSuccess;
        }
      }
    }

    &.bs-tooltip-left {
      .tooltip-arrow {
        &:before {
          border-left-color: $colorSuccess;
        }
      }
    }

    .tooltip-inner {
      background-color: $colorSuccess;
    }
  }

  &.secondary {

    &.bs-tooltip-top {
      .tooltip-arrow {
        &:before {
          border-top-color: $colorSecondary;
        }
      }
    }

    &.bs-tooltip-right {
      .tooltip-arrow {
        &:before {
          border-right-color: $colorSecondary;
        }
      }
    }

    &.bs-tooltip-bottom {
      .tooltip-arrow {
        &:before {
          border-bottom-color: $colorSecondary;
        }
      }
    }

    &.bs-tooltip-left {
      .tooltip-arrow {
        &:before {
          border-left-color: $colorSecondary;
        }
      }
    }

    .tooltip-inner {
      background-color: $colorSecondary;
    }
  }

  &.info {

    &.bs-tooltip-top {
      .tooltip-arrow {
        &:before {
          border-top-color: $colorInfo;
        }
      }
    }

    &.bs-tooltip-right {
      .tooltip-arrow {
        &:before {
          border-right-color: $colorInfo;
        }
      }
    }

    &.bs-tooltip-bottom {
      .tooltip-arrow {
        &:before {
          border-bottom-color: $colorInfo;
        }
      }
    }

    &.bs-tooltip-left {
      .tooltip-arrow {
        &:before {
          border-left-color: $colorInfo;
        }
      }
    }

    .tooltip-inner {
      background-color: $colorInfo;
    }
  }

  &.warning {

    &.bs-tooltip-top {
      .tooltip-arrow {
        &:before {
          border-top-color: $colorWarn;
        }
      }
    }

    &.bs-tooltip-right {
      .tooltip-arrow {
        &:before {
          border-right-color: $colorWarn;
        }
      }
    }

    &.bs-tooltip-bottom {
      .tooltip-arrow {
        &:before {
          border-bottom-color: $colorWarn;
        }
      }
    }

    &.bs-tooltip-left {
      .tooltip-arrow {
        &:before {
          border-left-color: $colorWarn;
        }
      }
    }

    .tooltip-inner {
      background-color: $colorWarn;
      color: #000000;
    }
  }

  &.error {

    &.bs-tooltip-top {
      .tooltip-arrow {
        &:before {
          border-top-color: $colorError;
        }
      }
    }

    &.bs-tooltip-right {
      .tooltip-arrow {
        &:before {
          border-right-color: $colorError;
        }
      }
    }

    &.bs-tooltip-bottom {
      .tooltip-arrow {
        &:before {
          border-bottom-color: $colorError;
        }
      }
    }

    &.bs-tooltip-left {
      .tooltip-arrow {
        &:before {
          border-left-color: $colorError;
        }
      }
    }

    .tooltip-inner {
      background-color: $colorError;
    }
  }
}