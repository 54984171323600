@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";
@use "../../../resources/scss/themes/dark-theme";

@mixin buttonStyleFontColor($fontColor) {
  .icon-container {
    >i {
      color: $fontColor;
      margin: 0;
    }

    >svg {
      fill: $fontColor;
    }
  }

  color: $fontColor;
}

@mixin _buttonStyle($baseColor, $darkColor, $lightColor, $fontColor, $useContrastBordered, $useContrastText) {
  background-color: $baseColor;
  border-color: $baseColor !important;
  @include buttonStyleFontColor($fontColor);

  &:not(.disabled):hover {
    @include mixins.rippleBackground($darkColor);
  }

  &:not(.disabled):active {
    background-color: $lightColor;
    background-size: 100%;
    transition: background 0s;
  }

  &.selected {
    background-color: darken($darkColor, 5%);
  }

  &.rtl {
    direction: rtl;
  }

  &.text {
    @if $useContrastText ==true {
      @include buttonStyleFontColor(themes.textContrast(themes.themed("background50")));
    }

    @else {
      @include buttonStyleFontColor($baseColor);
    }
  }

  &.bordered {
    @if $useContrastBordered ==true {
      @include buttonStyleFontColor(themes.textContrast(themes.themed("background50")));
    }

    @else {
      @include buttonStyleFontColor($baseColor);
    }
  }

  &.text,
  &.bordered {
    background-color: transparent;

    &:not(.disabled):hover {
      @include mixins.rippleBackground(rgba($darkColor, 0.2));
    }

    &:not(.disabled):active {
      background-color: rgba($lightColor, 0.2);
      background-size: 100%;
      transition: background 0s;
    }

    &.selected {
      background-color: rgba($darkColor, 0.3);
    }
  }

  &.bordered {
    @include mixins.after() {
      border: 2px solid $baseColor;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: themes.$radiusSmall;
    }
  }
}

@mixin buttonStyle($colorName, $startingIndicator: 500, $useContrastBordered: false, $useContrastText: false) {
  @include themes.themify(themes.$themes) {
    $baseColor: themes.themed($colorName+$startingIndicator);
    $darkColor: themes.themed($colorName+($startingIndicator + 200));
    $lightColor: themes.themed($colorName+($startingIndicator - 200));
    $fontColor: themes.textContrast($baseColor);
    @include _buttonStyle($baseColor, $darkColor, $lightColor, $fontColor, $useContrastBordered, $useContrastText);
  }
}


.fetura-button {
  $hoverOpacity: 0.1;
  position: relative;
  border: none;
  margin: 0 0.25rem 1rem;
  padding: themes.$paddingSmallVertical 0 themes.$paddingSmallVertical 0;
  @include mixins.noBreakWord();

  >* {
    pointer-events: none;
  }

  &:not(.round) {
    padding: themes.$paddingSmallVertical themes.$paddingSmallHorizontal themes.$paddingSmallVertical themes.$paddingSmallHorizontal;

    .icon-container {

      &.has-children {
        margin-right: 0.5rem;
      }
    }

  }

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &:not(.no-text-transform) {
    text-transform: uppercase;
  }

  border-radius: themes.$radiusSmall;
  //font-size: 1rem;
  line-height: 1.618;
  background-position: center;
  transition: background 0.8s;
  @include buttonStyle('background', 300, true, true);

  &.primary {
    @include buttonStyle('primary', 500, true);
  }

  &.secondary {
    @include buttonStyle('secondary', 700, true);
  }

  &.success {
    @include buttonStyle('success', 500, true);
  }

  &.info {
    @include buttonStyle('info', 500, true);
  }

  &.warning {
    @include buttonStyle('warning', 500, true);
  }

  &.error {
    @include buttonStyle('error', 500, true);
  }

  &.white {
    @include buttonStyle('white');
  }

  &.menu-color {
    @include themes.themify(themes.$themes) {
      $baseColor: themes.themed("background50");
      $darkColor: themes.themed("background300");
      $lightColor: themes.themed("background50");
      $fontColor: themes.textContrast($baseColor);
      @include _buttonStyle($baseColor, $darkColor, $lightColor, $fontColor, true, true);
    }
  }

  &.font-color {
    @include themes.themify(themes.$themes) {
      $baseColor: themes.themed("textColorDark");
      $darkColor: themes.themed("textColorDark");
      $lightColor: themes.themed("textColorDark");
      $fontColor: themes.themed("textColorDark");
      @include _buttonStyle($baseColor, $darkColor, $lightColor, $fontColor, true, true);
    }
  }

  &.disabled {
    filter: contrast(0.38) brightness(1.2) saturate(0);
    opacity: 0.95;
    cursor: not-allowed;
  }

  &.round {
    font-size: 0;

    .icon-container {
      font-size: 1rem;
    }

    height: 2rem;
    width: 2rem;
    border-radius: 50%;

  }

  &.icon-only {
    text-overflow: unset;
    white-space: normal;
  }
}

.theme-dark {
  .fetura-button {
    &:not(.primary):not(.secondary):not(.success):not(.info):not(.warning):not(.error):not(.white):not(.text):not(.bordered) {
      &:before {
        pointer-events: none;
        content: ' ';
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        position: absolute;
        border-radius: inherit;
        box-shadow: 0 0 2px 0 map-get(dark-theme.$darkColorTheme, "textColorLight");
      }
    }
  }
}