@use "../../../resources/scss/utils/mixins";
@use "../../../resources/scss/themes/themes";

.timeline_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  justify-content: center;
  align-items: center;
}

.timeline_line {
  width: 2px;
  margin-top: 5px;
  border-radius: 2px;
  @include themes.themify(themes.$themes) {
    background: themes.themed("background500");
  }
}

.timeline_card {
  position: relative;
  width: 100%;

  .title {
    font-weight: 500;
  }

  .changed-item {
    text-align: left;

    > small {
      display: block;
      font-weight: 600;
      opacity: 0.75;
    }

    > div {
      display: inline-block;
      border-radius: 2px;
      padding: 2px;
    }
  }

  &--hidden {
    height: 100px;
    overflow: hidden;

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      //background: red;
      @include themes.themify(themes.$themes) {
        background: linear-gradient(0deg, #{themes.themed("background50")}FF 10%, #{themes.themed("background50")}00 100%);
      }
    }
  }

  .floating-button {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    margin: 0 !important;
  }
}