@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";

.basic-info-box {
  .info-row {

    &:not(:last-child) {
      margin-bottom: .5rem;
    }

    .fa-exclamation-mixins.triangle {
      @include themes.themify(themes.$themes) {
        color: themes.themed("warning500")
      }
    }

    @include themes.themify(themes.$themes) {
      border-bottom: 1px solid themes.themed("primary500");
    }

    &.topigs {
      border-bottom: 1px solid #D10074;
    }

    .info-column {
      @include mixins.noBreakWord();

      &.header {
        font-weight: 600;
      }

      .fa-clipboard-check {
        @include themes.themify(themes.$themes) {
          font-size: 1.25rem;
          margin-left: 0.5rem;
          color: themes.themed("success500")
        }
      }
    }
  }

  &-edit {
    position: absolute;
    right: .4rem;
    top: 0;
    margin: 0;
  }

  fieldset {
    position: relative;

    legend {
      margin-bottom: 0;
      font-size: 1.2rem !important;
      font-weight: 700 !important;
    }
  }
}