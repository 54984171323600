@use "../../../resources/scss/themes/themes";

.file-show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1050;

  &-backdrop {
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000;
  }

  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    &.pdf {
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .react-pdf__Page {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    >* {
      max-width: 85vw;
      max-height: 85vh;
    }
  }

  .loading {
    background: none !important;

    @include themes.themify(themes.$themes) {
      color: themes.themed("textColorLight") !important;
    }
  }

  &-close {
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
    margin: 0;
    transform: translateY(-50%);

    @include themes.themify(themes.$themes) {
      color: themes.themed("textColorLight") !important;
    }
  }

  &-button {
    position: absolute;
    font-size: 3rem;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 200ms ease-in-out;
    cursor: pointer;
    $fromSide: 0;

    @include themes.themify(themes.$themes) {
      color: themes.themed("textColorLight") !important;
    }

    &.previous {
      left: $fromSide;
    }

    &.next {
      right: $fromSide;
    }
  }

  .file-show-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: .5rem;
    z-index: 999;

    @include themes.themify(themes.$themes) {
      background: themes.themed("background700");
      color: themes.textContrast(themes.themed("background700"));
    }
  }

  &-controls {
    position: absolute;
    right: 3.7rem;
    top: 50%;
    transform: translateY(-50%);

    .fetura-button {
      margin-bottom: 0;

      @include themes.themify(themes.$themes) {
        color: themes.themed("textColorLight") !important;
      }
    }
  }
}