.sow-results {
  .gauge-main {
    display: flex;
    align-items: center;
    justify-content: center;
    .gauge-item {
      > svg {
        width: 13rem;
        > text {
          font-size: 0.01rem;
        }
      }
      .gauge-text {
        margin-top: .7em;
      }
    }
  }

  .gauge-details {
    .gauge-item {
      > svg {
        width: 6rem;
      }
      .gauge-text {
        font-size: 1.5rem;
      }
    }
  }
}
