.color-item {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }
}
