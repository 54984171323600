@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";

@mixin infoBoxStyle($baseColorName) {
  @include themes.themify(themes.$themes) {
    border-bottom: 2px solid themes.themed($baseColorName+'900');
    background: themes.themed($baseColorName+'500');
    color: themes.textContrast(themes.themed($baseColorName+'500'));

    &:before {
      color: themes.themed($baseColorName+'500');
      transition: color 250ms ease-in-out;
    }

    &.outlined {
      border-radius: 0;
      $color: themes.themed($baseColorName+'500');
      border-bottom: none;
      border-left: 2px solid $color;
      background: #{$color}1C;

      color: themes.textContrast(themes.themed("background200"));

      i {
        color: themes.themed($baseColorName+'500');
      }
    }
  }
}

.fetura-info-box {
  border: none;
  font-size: 1rem;
  padding: 0.25rem 0.25rem;
  line-height: 1.618;
  border-radius: 0.3rem;
  transition: background-color 250ms ease-in-out;
  margin-bottom: 1rem;
  @include infoBoxStyle('background');

  &.info {
    @include infoBoxStyle('info');
  }

  &.success {
    @include infoBoxStyle('success');
  }

  &.error {
    @include infoBoxStyle('error');
  }

  &.warning {
    @include infoBoxStyle('warning');
  }

  &.primary {
    @include infoBoxStyle('primary');
  }

  &.secondary {
    @include infoBoxStyle('secondary');
  }

  a,
  b {
    color: inherit !important;
  }

  &.sticky-item {
    z-index: 2 !important;
  }
}