.accept-cert {
  padding: 1rem;

  h2 {
    flex-wrap: wrap;
    white-space: normal;
  }

  ol {
    padding-left: 1rem;

    li {
      text-align: justify;
    }
  }

  img {
    width: 100%;
    margin-bottom: 1rem;
  }
}