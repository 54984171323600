@use "../../resources/scss/themes/themes";
@use "../../resources/scss/utils/mixins";


.fetura--find-animal {
  &__exit-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  &__popover {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    overflow: hidden;
    z-index: 1;
    @include mixins.shadow(big);
    border-radius: themes.$paddingSmallVertical;

    @include themes.themify(themes.$themes) {
      background: themes.themed("background50");
    }
  }
}