@use "../../../../resources/scss/themes/themes";
@use "../../../../resources/scss/utils/mixins";
@use "../../../../resources/scss/themes/dark-theme";
@use "../../../../resources/scss/themes/base-colors";

//@mixin styleGradient($background, $blend: none) {
//  @include themes.themify(themes.$themes) {
//
//    .color-#{$background} {
//      $color: mix(themes.themed("background300"), themes.themed(#{$background}500), 90%);
//      stop-color: $color;
//    }
//
//    &__smalls {
//      .color-#{$background} {
//        $color: mix(themes.themed("background300"), themes.themed(#{$background}500), 95%);
//        stop-color: $color;
//      }
//    }
//  }
//}
//
//.map-gradient {
//
//  @include styleGradient("error");
//  @include styleGradient("info");
//  @include styleGradient("success");
//  @include styleGradient("warning");
//}

.theme-dark {
  .farm-map-light-1 {
    stop-color: #7e7e7e;
  }

  .farm-map-light-2 {
    stop-color: #b1acac;
    stop-opacity: 0;
  }
}

.theme-normal {
  .farm-map-light-1 {
    stop-color: #eaf48f;
  }

  .farm-map-light-2 {
    stop-color: #d9ff00;
    stop-opacity: 0.2;
  }
}
