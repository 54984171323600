.fetura-helper-icon {
  position: relative;
  font-size: 0.75rem;
  height: 1.5rem !important;
  width: 1.5rem !important;
  line-height: 1.5rem !important;

  &.type-round {
    border-radius: 50%;
  }

  &.size-m {
    font-size: 1rem;
  }
}
