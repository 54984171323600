@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";

@mixin highLightColor($colorName, $indicator: 500) {
  @include themes.themify(themes.$themes) {
    $backgrounColor: themes.themed($colorName+$indicator);
    $textColor: themes.textContrast($backgrounColor);
    background: $backgrounColor;
    color: $textColor;

  }
}


.fetura-highlight {
  border: none;
  border-radius: themes.$radiusSmall;
  padding: 0.1rem 0.2rem;

  .fetura-highlight {
    padding: 0.1rem 0.2rem;
    margin-right: -0.2rem;
    margin-left: -0.2rem;
  }

  @include highLightColor('background');

  &.primary {
    @include highLightColor('primary');
  }

  &.secondary {
    @include highLightColor('secondary', 700);
  }

  &.success {
    @include highLightColor('success');
  }

  &.info {
    @include highLightColor('info');
  }

  &.warning {
    @include highLightColor('warning');
  }

  &.error {
    @include highLightColor('error');
  }
}