@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";


.msgbox {
  display: block;
  position: relative;

  &__paragraph {
    font-style: italic;
    margin-bottom: 0;
  }

  &__body {
    font-size: 0.8rem;
    height: auto;
    border-radius: 5px;
    padding: 6px 8px;
    word-break: break-word;
    @include themes.themify(themes.$themes) {
      $color: themes.themed('background300');
      background: $color;
      color: themes.textContrast($color);
    }

  }


  &__arrow {
    @include themes.themify(themes.$themes) {
      $color: themes.themed('background300');
      &--left {
        &:after {
          left: -14px;
          top: 8px;
          @include mixins.triangle(left, 16px, 8px, $color);
        }
      }

      &--right {
        &:after {
          right: -14px;
          top: 8px;
          @include mixins.triangle(right, 16px, 8px, $color);
        }
      }
    }

    &:after {
      position: absolute;
    }


  }

}





