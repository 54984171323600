.printable {
    display: none;
}

@media print {
    @page {
        size: portrait;
        margin: auto;
    }

    body {
        .tooltip {
            display: none;
        }

        .table-grid-filter {
            display: none;
        }

        opacity: 1 !important;
    }

    #root {
        background: white !important;

        > *:not(.printable) {
            display: none;
        }
    }

    .printable {
        display: block;
        width: 100%;
        height: 100%;
        color: #000000;

        &-title {
            margin-bottom: 1rem;

            h2,
            h3 {
                justify-content: center;
            }

            h5 {
                display: block;
                text-align: center;
            }
        }

        .table-item {
            white-space: normal !important;
        }

        img {
            width: 250px;
        }

        a {
            color: #000000 !important;
        }

        .progress {
            span {
                color: #000000 !important;
                text-shadow: none !important;
            }
        }
    }
}
