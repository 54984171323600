@use "../../../../resources/scss/themes/themes";
@use "../../../../resources/scss/utils/mixins";

.filter-animal-control {
    align-items: center;

    &-input {
        position: relative;
        flex-grow: 1;

        &:first-child {
            flex-grow: 2;
        }
    }

    &-counter {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
    }

    &-legend {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 1.3rem;
        height: 100%;

        >div {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-right: .5rem;
            }
        }

        &-color {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            margin-right: .3rem;

            &.warning {
                @include themes.themify(themes.$themes) {
                    background: themes.themed("warning500");
                }
            }

            &.error {
                @include themes.themify(themes.$themes) {
                    background: themes.themed("error500");
                }
            }

            &.info {
                @include themes.themify(themes.$themes) {
                    background: themes.themed("info500");
                }
            }

            &.success {
                @include themes.themify(themes.$themes) {
                    background: themes.themed("success500");
                }
            }
        }
    }
}