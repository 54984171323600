@use "sass:math";

@use "../../../../resources/scss/themes/themes";

.input-actions-wrapper-file-item {
  height: 100%;
  flex: 0 0 auto;
  cursor: pointer;
  position: relative;

  &:not(:last-child) {
    margin-right: .5rem;
  }

  &-image,
  &-name {
    height: 100%;
    transition: filter 200ms ease-in-out;
    border-radius: themes.$radiusSmall;

    @include themes.themify(themes.$themes) {
      border: 1px solid themes.themed("secondary300");
      background: themes.themed("background50");
    }
  }

  &-image {
    overflow: hidden;

    img {
      height: 100%;
    }
  }

  &-name {
    display: flex;
    padding-right: .5rem;

    > :first-child {
      flex: 0 0 3rem;
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
    }

    > :last-child {
      flex: 1 1 auto;
      max-width: 10rem;
      display: flex;
      justify-content: center;
      flex-direction: column;

      > :first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      small {
        text-transform: uppercase;
        opacity: .5;
      }
    }
  }

  &-remove {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;

    @include themes.themify(themes.$themes) {
      color: themes.themed("error400");
    }
  }

  &-arrow {
    position: absolute;
    right: .5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    opacity: 0;

    @include themes.themify(themes.$themes) {
      color: themes.themed("textColorLight");
    }
  }

  &:hover {

    .input-actions-wrapper-file-item-image,
    .input-actions-wrapper-file-item-name {
      filter: brightness(30%);
    }

    .input-actions-wrapper-file-item-remove,
    .input-actions-wrapper-file-item-arrow {
      opacity: 1;
    }
  }

}