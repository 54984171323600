@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";
@import "~@fortawesome/fontawesome-pro/scss/functions";
@import "~@fortawesome/fontawesome-pro/scss/variables";

.left-bar+.content {
  .view-container-modal {
    width: calc(100% - 3rem);
  }
}

.expanded+.content {
  .view-container-modal {
    width: calc(100% - 13.5rem);
  }
}

.theme {
  &.helper {
    &:not(.helper-opened) {
      .view-container-modal {
        width: calc(100% - 6rem);
      }
    }

    .expanded+.content {
      .view-container-modal {
        padding-right: 3rem;
        width: calc(100% - 13.5rem);
      }
    }
  }
}

.view-container-modal {
  >.modal-content {
    position: relative;

    &.show-loading {
      &:after {
        content: ' ';
        display: block;
        z-index: 1;
        position: absolute;

        @include themes.themify(themes.$themes) {
          background: themes.themed('background200');
        }

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      &:before {
        position: sticky;
        font: normal normal normal 3rem "Font Awesome 6 Pro";
        content: fa-content($fa-var-circle-notch);
        animation: fa-spin 2s infinite linear;
        top: 50%;
        transform-origin: center;
        -webkit-filter: blur(0);
        filter: blur(0);
        z-index: 502;
        font-size: 5rem;
        width: 5rem;
        height: 5rem;
      }
    }
  }

  .view-container {
    position: relative;
  }


  z-index: 999;

  header {
    top: 0 !important;
  }

  @include themes.themify(themes.$themes) {
    background: themes.themed('background200');
  }

  position: fixed;
  height: 100%;
  will-change: translateX;
  transition: 300ms ease-in-out;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateX(100%);

  header {
    z-index: 501;
  }

  .loading {
    z-index: 500;
  }

  &.modal-in-enter-done {
    transform: translateX(0);
    @include mixins.shadow(big);
  }
}