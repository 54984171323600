@use "../../resources/scss/themes/themes";
@use "../../resources/scss/utils/mixins";

.notify-view {
  .notify-item {
    padding-left: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid grey;
    }

    .title {
      font-weight: bold;
      position: relative;

      .icon {
        position: absolute;
        left: -2rem;
        width: 2rem;
        height: 100%;
        display: flex;
        justify-content: center;
        top: 0;
        align-items: center;
      }

      @include mixins.breakpointWithHelper(themes.$md) {
        .time {
          display: block;
          margin-left: 0 !important;
        }
      }

      .time {
        opacity: 0.5;
        margin-left: 0.5rem;
        font-weight: lighter;
        font-style: italic;

      }
    }
  }
}