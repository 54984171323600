@use "../../resources/scss/themes/themes";
@use "../../resources/scss/utils/mixins";

.loading {
  box-sizing: border-box !important;
  position: absolute;
  min-height: 10rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 5rem;
  text-align: center;
  z-index: 998;

  @include themes.themify(themes.$themes) {
    background: themes.themed('background300');
  }

  &.type-component {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  &.type-container {
    >i {
      position: sticky;
      top: 5rem;
    }
  }

  &.bg-inherit {
    background: inherit !important;
  }

  transition: background .15s ease-out,
  opacity .15s ease-out !important;

  &.show {
    transition: background 0s ease-out, opacity 0s ease-out !important;
  }

  .loading-text {
    font-size: 1.2rem;
    text-transform: uppercase;
    background: inherit;
  }

}


.loading-list-item {
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 3.5rem;
  text-align: center;
  z-index: 998;

  &.animate {
    transition: background 200ms themes.$easeStandard, opacity 200ms themes.$easeStandard;
  }

  >i {
    padding: 1rem;
  }

  @include themes.themify(themes.$themes) {
    background-color: themes.themed('background300');
  }
}