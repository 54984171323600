@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";

.grid-wrapper {
  overflow: auto;
  height: 40rem;
  max-height: 100%;

  .sticky-header {
    z-index: 2;
    position: sticky;
    top: 0;

    @include themes.themify(themes.$themes) {
      background-color: themes.themed("background50");
    }
  }
}