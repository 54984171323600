@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";

.tree-select {
  position: relative;

  .fetura-input {
    input {
      cursor: pointer;
    }
  }

  .tree-select-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.tree-select-menu {
  position: absolute;
  width: 100%;
  max-height: 20rem;
  top: 2.5rem;
  padding: themes.$paddingSmallVertical themes.$paddingBigHorizontal;
  z-index: 9998;
  overflow: auto;
  min-width: 20rem;

  @include themes.themify(themes.$themes) {
    background: themes.themed("background50");
    color: themes.textContrast(themes.themed("background50"));
  }

  .tree-select-menu-item {
    margin-left: 1.2rem;

    span {
      margin-bottom: 0.5rem;
      cursor: pointer;
    }

    .tree-select-menu-item-triangle {
      cursor: pointer;
      position: relative;
      margin-right: 0.5rem;
      transform: translateY(-0.1rem);
      transition: transform 200ms ease-in-out;

      @include themes.themify(themes.$themes) {
        @include mixins.triangle(right, .5rem, .3rem, themes.themed("primary500"));
        display: inline-block;
      }

      &.opened {
        transform: rotate(90deg);
      }
    }

    &.no-children {
      margin-left: 2.2rem;
    }

    &.level-0 {
      margin-left: 0;
    }

    &.active {
      font-weight: 700;

      .tree-select-menu-item {
        font-weight: 400;
      }
    }
  }

  &.mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    max-height: unset;

    .close-button {
      position: absolute;
      font-size: 1.2rem;
      right: 1rem;
      top: 0.5rem;

      @include themes.themify(themes.$themes) {
        color: themes.themed("error500");
      }
    }
  }
}