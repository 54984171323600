@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";

.fetura-chart {

  user-select: none;

  //svg {
  //  overflow: visible !important;
  //}
  position: relative;
  will-change: auto;
  width: 100%;
  height: 100%;

  &-maximize {
    position: absolute;
    right: 0;
    left: unset !important;
    top: 0;
    z-index: 2;
    margin: 0;
    font-size: 1rem !important;
  }

  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 1rem 1rem 2rem 1rem;
    width: 100vw;
    height: 100vh;

    @include themes.themify(themes.$themes) {
      background: themes.themed("background50");
    }
  }

  .recharts-custom-legend {
    position: relative;
    display: flex;
    overflow-x: auto;
    z-index: 1;

    // hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      flex-shrink: 0;
      margin-right: 1rem;

      &:first-child {
        margin-left: 3rem;
      }

      &:last-child {
        margin-right: 3rem;
      }
    }
  }

  .reference-line-text {
    paint-order: stroke;
  }

  .recharts-surface {
    overflow: visible;
  }

  .recharts-tooltip-cursor {
    fill: none;
  }

  .recharts-default-tooltip {
    @include themes.themify(themes.$themes) {
      z-index:9999;
      background: themes.themed("background100") !important;
      color: themes.textContrast(themes.themed("background100"));
      border-radius: 0.5rem;
      @include mixins.shadow();
      border: 1px solid themes.themed("background50") !important;
      max-width:400px;
    }
  }

  .recharts-brush {
    >rect {
      &:not([class]) {
        @include themes.themify(themes.$themes) {
          fill: themes.themed("background50") !important;
        }
      }
    }
  }

  .chart-summary {
    display: block;
    font-size: 1.5rem;
    line-height: 3rem;
    text-align: right;
  }

  .fetura-chart-container {
    min-width: 100%;
    min-height: 5rem;

    &.has-children {
      height: calc(100% - 3rem) !important;
    }

    @include themes.themify(themes.$themes) {

      .recharts-cartesian-axis-line,
      .recharts-cartesian-axis-tick-line {
        stroke: themes.textContrast(themes.themed('background200'));
      }

      text {
        fill: themes.textContrast(themes.themed('background200'));
      }
    }
  }

  .save-as-excell-container {
    position: absolute;
    top: 0;
    right: 2rem;
    transition: opacity 100ms themes.$easeAccelerate;
    z-index: 2;

    .fetura-button {
      @include themes.themify(themes.$themes) {
        color: themes.textContrast(themes.themed('background200'));
        display: flex;
        justify-content: center;
        align-content: center;
      }
    }
  }


  .fetura-checkbox {
    position: absolute !important;
    right: 0;
    bottom: 0.85rem;
    margin: 0 !important;
  }

  .fetura-chart-domain-input {
    position: absolute;
    left: .3rem;
    top: 1.3rem;
    display: flex;
    align-items: center;

    .fetura-input {
      display: inline-block;
      width: 3rem;
      margin: 0;

      input {
        text-align: right;
      }
    }

    .fa-exclamation-circle {
      font-size: 1.5rem;

      @include themes.themify(themes.$themes) {
        color: themes.themed("warning500");
      }
    }
  }
}

@include mixins.breakpointWithHelper(themes.$sm) {
  .fetura-chart {
    font-size: 12px;
  }
}

@include mixins.breakpointWithHelper(themes.$xs) {
  .fetura-chart-domain-input {
    top: 25px;
    left: 22px;
  }

}