@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";

@mixin badge($color) {
    @include themes.themify(themes.$themes) {
        background: mix(
            themes.themed("secondary800"),
            themes.themed($color + "900"),
            90%
        );
        color: themes.themed($color + "50");
        border: 1px solid #{themes.themed($color + "500")};
    }
}

.left-bar-mobile + .content,
.left-bar-mobile-menu + .content {
    header {
        transition: padding-left 100ms ease-in-out;

        &.mobile {
            padding-left: 4.7rem !important;
            padding-right: 4.7rem !important;
        }
    }
}

header {
    position: sticky;
    top: -3rem;
    z-index: 3;
    padding: 0 themes.$paddingBigVertical;
    //width: 100vw;
    width: 100%;
    overflow: hidden;

    @include themes.themify(themes.$themes) {
        background: themes.themed("background50");
    }

    h2 {
        height: auto;
        margin-left: 0.5rem;
        margin-bottom: 0;
        padding: 0.5rem 0;

        @include themes.themify(themes.$themes) {
            color: themes.themed("primary500");

            i {
                color: themes.textContrast(themes.themed("background50"));
            }
        }

        > div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding: 0.2rem 0;

            > a {
                display: inline-block;
            }
        }
    }

    &.fixed {
        position: fixed;
        top: 0;
        @include mixins.shadow();
    }

    &:not(.fixed) {
        h2 {
            transition: padding 200ms ease-in-out;
        }

        &.big {
            h2 {
                padding-top: 20vh;
                padding-bottom: 20vh;
            }
        }
    }

    .tabs {
        z-index: 1;
        display: flex;
        position: relative;
        //margin-top: 1rem;
        height: 2.5rem;
        margin-left: -1rem;
        margin-right: -1rem;

        .fetura-button {
            margin: 0;
            font-weight: 600;
            border-radius: 0;
            flex-shrink: 0;
            display: flex;
            line-height: 1rem;
            align-items: center;

            &.active {
                @include themes.themify(themes.$themes) {
                    &:after {
                        content: " ";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 4px;
                        background: themes.themed("primary500");
                    }
                }
            }

            span {
                position: relative;
            }

            .info-badge {
                font-size: 0.5rem;
                line-height: 0.6rem;
                text-transform: uppercase;
                padding: 0 0.3rem;
                border-radius: themes.$radiusSmall;
                position: absolute;
                top: -0.7rem;
                right: -1rem;

                &.new {
                    @include badge("primary");
                }

                &.trial {
                    @include badge("info");
                }

                &.beta {
                    @include badge("warning");
                }
            }
        }

        .menu-button-container {
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            width: 3rem;

            @include themes.themify(themes.$themes) {
                background: themes.themed("background50");
            }

            &:before {
                left: -1rem;
                content: " ";
                position: absolute;
                width: 1rem;
                height: 100%;

                @include themes.themify(themes.$themes) {
                    background: linear-gradient(
                        to right,
                        rgba(0, 0, 0, 0) 0%,
                        #{themes.themed("background50")}ff 100%
                    );
                }
            }
        }

        .menu-button {
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
        }
    }

    &.mobile {
        &:not(.tabs-on-mobile) {
            .tabs {
                z-index: -3;
                height: 0 !important;
            }
        }

        //padding-left: 4.5rem;

        //.tabs {
        //  margin-left: -4rem;
        //}
    }

    &.sticky {
        top: 0;
    }
}

@media (max-width: 1200px) {
    header h2 {
        justify-content: center;
        font-size: calc(1.8rem + 0.9vw);
        margin: 0;

        div {
            padding: 0.5rem 0;
        }
    }
}

.header-menu {
    .fetura-button {
        display: flex;
        align-items: center;

        .info-badge {
            margin-left: 0.4rem;
            font-size: 0.6rem;
            line-height: 0.6rem;
            text-transform: uppercase;
            padding: 0 0.3rem;
            border-radius: themes.$radiusSmall;

            &.new {
                @include badge("primary");
            }

            &.trial {
                @include badge("info");
            }

            &.beta {
                @include badge("warning");
            }
        }
    }
}
