@use "../../../resources/scss/themes/themes";

.fetura-video-player {
  width: 100%;
  position: relative;
  overflow: hidden;

  video {
    width: 100%;
    display: block;
  }

  &-controls {
    position: absolute;
    bottom: -2rem;
    width: 100%;
    transition: bottom 200ms ease-in-out;

    @include themes.themify(themes.$themes) {
      background: rgba(themes.themed("background900"), .4);
    }

    &-buttons {
      display: flex;
      justify-content: space-between;

      &-first {
        display: flex;
        align-items: center;

        &-duration {
          margin-left: .5rem;

          @include themes.themify(themes.$themes) {
            color: themes.themed("textColorLight");
          }
        }
      }

      .fetura-button {
        margin: 0;
        border-radius: 0;
        height: 2rem;
      }
    }
  }

  &:hover {
    .fetura-video-player-controls {
      bottom: 0;
    }
  }

  &.fullscreen {
    video {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      max-height: 100%;
    }
  }

  &-pip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    text-transform: uppercase;
    flex-wrap: wrap;
    text-align: center;

    @include themes.themify(themes.$themes) {
      background: themes.themed("background900");
      color: themes.textContrast(themes.themed("background900"));
    }
  }
}