@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";
@use "../../../resources/scss/utils/variables";

@mixin styleCard($prefix, $density: 50) {
  @include themes.themify(themes.$themes) {
    $bgColor: none;

    @if $prefix =="background" {
      $bgColor: themes.themed($prefix+$density);
    }

    @else {
      $bgColor: themes.themed($prefix+$density);
      $bgColor: mix($bgColor, themes.themed("background50"), 25%);
    }

    background: $bgColor;
    color: themes.textContrast($bgColor);

    .loading:not(.bg-inherit) {
      background: $bgColor !important;
    }

    &.selected {
      $selectedColor: mix($bgColor, themes.textContrast($bgColor), 85%);
      background: $selectedColor;
      color: themes.textContrast($selectedColor);
    }
  }
}

.fetura-card {
  border: none;
  position: relative;
  overflow: hidden;
  border-radius: themes.$radiusBig;
  margin-bottom: 1rem;
  transition: opacity 200ms ease-in-out;

  &.type-container {
    padding: 0;
  }

  &.type-row {
    padding: 0;
  }

  &.type-col {
    padding: .75rem;
  }

  &:after {
    content: '';
    clear: both;
    display: table;
  }

  &:not(.no-shadow) {
    @include mixins.shadow();
  }

  @each $density in variables.$easeAccelerate {
    &.color-#{$density} {
      @include styleCard("background", $density);

      .loading {
        @include styleCard("background", $density);
      }
    }
  }

  &.transparent {
    background: transparent;
    color: inherit;
  }

  &.inactive {
    opacity: 0.5;
    filter: grayscale(1);
  }

  &.disabled {
    opacity: 0.4;
    filter: grayscale(1);
    pointer-events: none;
    box-shadow: none !important;

    @include themes.themify(themes.$themes) {
      border: 1px solid themes.themed("disabled500");

    }
  }

  @include themes.themify(themes.$themes) {

    &.success,
    &.primary {
      background: themes.themed("primary500");
      color: themes.textContrast(themes.themed("primary500"));
    }

    &.secondary {
      background: themes.themed("secondary500");
      color: themes.textContrast(themes.themed("secondary500"));
    }

    &.info {
      background: themes.themed("info500");
      color: themes.textContrast(themes.themed("info500"));
    }

    &.warning {
      background: themes.themed("warning500");
      color: themes.textContrast(themes.themed("warning500"));
    }

    &.error {
      background: themes.themed("error500");
      color: themes.textContrast(themes.themed("error500"));
    }

  }

  @each $density in variables.$easeAccelerate {
    &.color-info-#{$density} {
      @include styleCard("info", $density);

      .loading {
        @include styleCard("info", $density);
      }
    }

    &.color-primary-#{$density} {
      @include styleCard("primary", $density);

      .loading {
        @include styleCard("primary", $density);
      }
    }
  }
}