@use "../../../../resources/scss/themes/themes";
@use "../../../../resources/scss/utils/mixins";

.chart-overlay {
  .fetura-button {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 2;
  }

  @include mixins.shadow(big);
  position: absolute;
  top: 0;
  left: 0;

  @include themes.themify(themes.$themes) {
    color: themes.textContrast(themes.themed('background50'));
    background: themes.themed('background50');
  }

  width: 100%;
  height: calc(100% - 15px);
  z-index: 1;
}

.chart-template-no-data {
  padding:1.5rem;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(10px);
  z-index: 2;
  h5,
    div {
    white-space: unset;
    text-align: center;
  }
}