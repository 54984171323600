@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";

.fetura-grid {
    &.allow-multiline {
        .table-item {
            white-space: normal !important;
        }
    }

    font-size: 1rem;
    display: flex;
    flex-direction: column;

    .more-options {
        width: 13%;
        float: right;
        height: 10%;
        margin-left: auto;
    }

    .multiValues {
        width: 100%;
        padding-right: 0;
        margin-left: auto;
    }

    .table-item-title {
        display: none;
    }

    .body-container {
        .table-row-box {
            .table-row {
                &.error {
                    @include themes.themify(themes.$themes) {
                        background: themes.themed("error500");
                        color: themes.textContrast(themes.themed("error500"));

                        a,
                        i {
                            color: themes.textContrast(
                                themes.themed("error500")
                            );
                        }
                    }
                }

                &.warning {
                    @include themes.themify(themes.$themes) {
                        background: themes.themed("warning500");
                        color: themes.textContrast(themes.themed("warning500"));

                        a,
                        i {
                            color: themes.textContrast(
                                themes.themed("warning500")
                            );
                        }
                    }
                }

                &.info {
                    @include themes.themify(themes.$themes) {
                        background: themes.themed("info500");
                        color: themes.textContrast(themes.themed("info500"));

                        a,
                        i {
                            color: themes.textContrast(
                                themes.themed("info500")
                            );
                        }
                    }
                }

                &.success {
                    @include themes.themify(themes.$themes) {
                        background: themes.themed("success500");
                        color: themes.textContrast(themes.themed("success500"));

                        a,
                        i {
                            color: themes.textContrast(
                                themes.themed("success500")
                            );
                        }
                    }
                }
            }
        }

        &.bottom {
            .table-row-box {
                &:last-child {
                    .table-row {
                        border-bottom: unset;
                    }
                }
            }
        }
    }

    &.extensions {
        display: flex;
        width: 100%;
        flex-direction: row;

        .filter {
            width: 100%;
        }
    }

    .header-container {
        display: flex;
        width: 100%;

        @include themes.themify(themes.$themes) {
            border-bottom: 4px solid themes.themed("primary500");
        }

        .header-item {
            display: flex;
            flex-grow: 1;
            flex-basis: 100%;
            font-weight: 700;
            justify-content: center;
            align-items: flex-end;
            text-align: center;
            padding: themes.$paddingSmallVertical;

            &.selected-header,
            &.index {
                flex-basis: 10rem;
            }

            &.selected-header {
                .fetura-checkbox {
                    margin: 0;

                    label {
                        margin: 0;
                    }
                }
            }

            &.sortable {
                cursor: pointer;
                position: relative;
            }

            &.disabled-sort {
                cursor: not-allowed;
            }

            .header-sorting-icon {
                position: relative;
                right: 0;
            }
        }
    }

    .table-row {
        display: flex;
        flex-direction: row;
        transition: background 0.2s ease-in-out;

        @include themes.themify(themes.$themes) {
            border-bottom: 1px solid themes.themed("secondary500");
        }

        .table-item {
            flex-grow: 1;
            flex-basis: 100%;
            padding: themes.$paddingSmallVertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            justify-content: center;
            align-items: center;
            text-align: center;

            &.index,
            &.checkbox {
                flex-basis: 10rem;
                overflow: unset;
            }

            .fetura-checkbox,
            .fetura-radio {
                margin: 0 !important;

                label {
                    margin: 0 !important;
                }
            }

            .fetura-input {
                margin: 0 !important;
            }
        }

        &.selectable {
            cursor: pointer;

            &.selected {
                @include themes.themify(themes.$themes) {
                    $bgColor: themes.themed("primary500");
                    $selectedColor: mix(
                        $bgColor,
                        themes.themed("background50"),
                        50%
                    );
                    background: $selectedColor !important;
                    color: themes.textContrast($selectedColor);
                }
            }
        }

        &.even {
            @include themes.themify(themes.$themes) {
                background: mix(
                    themes.themed("background300"),
                    themes.themed("background400"),
                    50%
                );
            }
        }

        &:hover {
            @include themes.themify(themes.$themes) {
                background: mix(
                    themes.themed("background200"),
                    themes.themed("background300"),
                    50%
                );

                .loading {
                    background: mix(
                        themes.themed("background200"),
                        themes.themed("background300"),
                        50%
                    ) !important;
                }
            }
        }
    }

    &.redux {
        margin-bottom: 0.5rem;
    }

    .pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem;

        .fetura-button {
            margin-bottom: 0;
        }

        .fetura-input {
            display: inline-block;
            width: 2.5rem;

            input {
                text-align: center;
            }
        }

        &.small-pagination {
            justify-content: center;
        }
    }

    @for $i from 1 through 10 {
        .col-width-#{$i} {
            flex-basis: percentage($i) !important;
        }
    }

    .bottom-pinned-container,
    .top-pinned-container {
        @include themes.themify(themes.$themes) {
            border-top: 4px solid themes.themed("success500");
        }

        .pinned-row {
            padding: themes.$paddingSmallVertical;
        }
    }

    .isExpand-content {
        display: none;
    }
}

@include mixins.breakpointWithHelper(themes.$sm) {
    .fetura-grid {
        .pagination {
            display: block;
            text-align: center;

            .items-amount {
                margin-bottom: 0.5rem;
            }
        }
    }
}

@include mixins.breakpointWithHelper(themes.$xs) {
    .fetura-grid {
        .header-container {
            .header-item {
                &.selected-header,
                &.index {
                    flex-basis: 3rem;
                }
            }
        }

        .table-row {
            .table-item {
                &.index,
                &.checkbox {
                    flex-basis: 3rem;
                }
            }
        }
    }
}

.item-title {
    border-radius: 3px;
    word-wrap: normal;
    word-break: break-all;
    overflow: visible;
    white-space: normal;
    z-index: 999;
    padding: themes.$paddingSmallVertical;
    @include mixins.shadow(big);

    @include themes.themify(themes.$themes) {
        background: themes.themed("background200");
    }
}
