@use "../../resources/scss/utils/mixins";
@use "../../resources/scss/themes/themes";

@mixin notification-variant($color, $iconColor) {
  background-color: $color !important;
  border-left: 2px solid #{$color} !important;
  color: themes.textContrast($color) !important;

  >svg {
    color: $iconColor !important;
  }

  @include mixins.shadow(big);
}

.reapop_ {
  &_container- {

    &-top-right,
    &-top-center {
      .more-notifications {
        cursor: pointer;
        position: absolute;
        z-index: 3;
        transform: translateX(50%);
        top: -.75rem;
        padding: 0.5rem 1rem;
        border-radius: 2rem;
        @include mixins.shadow(big);

        @include themes.themify(themes.$themes) {
          border: 1px solid themes.themed("background400");
          background: themes.themed("background200");
          color: themes.textContrast(themes.themed("background200"));
        }
      }
    }

  }
  @include themes.themify(themes.$themes) {
    &_notification- {
      &-info {
        @include notification-variant(themes.themed("info500"), themes.textContrast(themes.themed("info500")));
      }

      &-loading {
        @include notification-variant(themes.themed("background200"), themes.themed("info500"));
      }

      &-success {
        @include notification-variant(themes.themed("success500"), themes.textContrast(themes.themed("success500")));

      }

      &-warning {
        @include notification-variant(themes.themed("warning500"), themes.textContrast(themes.themed("warning500")));

      }

      &-error {
        @include notification-variant(themes.themed("error500"), themes.textContrast(themes.themed("error500")));

      }
      &title,&message{
        text-wrap:wrap;
      }
    }
  }
}