@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";

.date-chooser {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;

  .arrow {

    .fetura-button {
      margin-bottom: 0;
    }

    &.left {
      margin-right: 0.25rem;
    }

    &.right {
      margin-left: 0.25rem;
    }
  }

  .carousel-container {
    width: calc(100% - 4.5rem);
    display: inline-block;
    overflow: hidden;
  }

  .date-carousel {
    display: flex;
    flex-direction: row;

    .date-item {
      display: inline-block;
      height: 5rem;
      flex: 1 0 20%;
      padding: 0.5rem;
      transition: transform 0.2s ease;

      .date-item-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        @include mixins.shadow();
      }

      &:hover {
        .date-item-content {
          @include themes.themify(themes.$themes) {
            background: themes.themed("secondary400");
            color: themes.textContrast(themes.themed("secondary400"))
          }
        }
      }

      &.selected {
        .date-item-content {
          @include themes.themify(themes.$themes) {
            background: themes.themed("primary500");
            color: themes.textContrast(themes.themed("primary500"))
          }
        }
      }
    }
  }
}