@use "../../../../resources/scss/themes/themes";
@use "../../../../resources/scss/utils/mixins";

.sql-input-history {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 2;
    max-height: 10rem;
    overflow: auto;

    @include mixins.shadow();

    @include themes.themify(themes.$themes) {
        background: themes.themed("background50");
    }

    &-item {
        padding: .5rem;
        cursor: pointer;
        display: flex;
        align-items: center;

        :first-child {
            flex: 1 1 100%;
        }

        :last-child:not(:only-child) {
            flex: 0 0 2rem;
            text-align: right;
        }

        &.hovered {

            @include themes.themify(themes.$themes) {
                background: themes.themed("background300");
            }
        }
    }
}