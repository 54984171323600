@use "../../resources/scss/themes/themes";
@use "../../resources/scss/utils/mixins";

.cookie-acceptance {
  position: fixed;
  bottom: .75rem;
  width: auto !important;
  max-width: 1024px;
  margin-right: .75rem !important;
  margin-left: .75rem !important;
  z-index: 2136;
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  opacity: 0.95;
  @include mixins.shadow(big);

  .fetura-button {
    margin: 0.75rem auto;
  }
}