@use "../../../../../resources/scss/themes/themes";
@use "../../../../../resources/scss/utils/mixins";

.filter-level {
  $marginLeft: 2rem;
  margin: 1rem 0;
  position: relative;

  .fetura-text-switch {
    width: 8rem;
    text-transform: uppercase;
    z-index: 2;
  }

  .filter-row {
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;
    position: relative;
    margin-left: $marginLeft;

    .fetura-input {
      margin-bottom: 0;
    }

    >.fetura-select,
    >.fetura-input,
    >.tree-select {
      margin-right: 1rem;
      flex-basis: 30%;
    }

    .fetura-button {
      margin-bottom: 0;
      flex-basis: 10%;
    }

    &:before {
      content: '';
      position: absolute;
      left: -$marginLeft * 0.5;
      top: 50%;
      transform: translateY(-50%);
      width: $marginLeft * 0.5;

      @include themes.themify(themes.$themes) {
        border-bottom: 1px solid themes.themed("secondary900");
      }
    }

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        height: calc(100% + 1rem);
        left: -$marginLeft * 0.5;
        top: 50%;

        @include themes.themify(themes.$themes) {
          border-left: 1px solid themes.themed("secondary900");
        }
      }
    }
  }

  &-controls {
    margin-top: 1rem;
    position: relative;
    margin-left: $marginLeft;

    &:before {
      content: '';
      position: absolute;
      width: $marginLeft * 0.5;
      left: -$marginLeft * 0.5;
      top: 50%;

      @include themes.themify(themes.$themes) {
        border-bottom: 1px dashed themes.themed("secondary900");
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: -$marginLeft * 0.5;
      height: calc(100% + 1rem);
      bottom: 50%;

      @include themes.themify(themes.$themes) {
        border-left: 1px dashed themes.themed("secondary900");
      }
    }

    .fetura-button {
      margin-bottom: 0;
    }
  }

  &.or {
    position: relative;

    .line {
      position: absolute;
      left: $marginLeft * 0.5;

      @include themes.themify(themes.$themes) {
        border-left: 1px solid themes.themed("secondary900");
      }

      .line-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 2rem;
        padding: .1rem .4rem;

        @include themes.themify(themes.$themes) {
          background: themes.themed("secondary300");
          @include mixins.shadow();
        }
      }
    }

    >.filter-level-controls {
      &:after {
        content: unset;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: $marginLeft * 0.5;
      bottom: 1rem;
      height: calc(100% / 2);

      @include themes.themify(themes.$themes) {
        border-left: 1px dashed themes.themed("secondary900");
      }
    }

    >.filter-level {
      margin-left: $marginLeft;

      .filter-data {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: -$marginLeft * 0.5;
          width: $marginLeft;
          top: 50%;
          transform: translateY(-50%);

          @include themes.themify(themes.$themes) {
            border-bottom: 1px solid themes.themed("secondary900");
          }
        }
      }
    }

    .or {
      .line {
        &:before {
          content: '';
          position: absolute;
          left: -$marginLeft;
          width: $marginLeft;
          top: 50%;
          transform: translateY(-50%);

          @include themes.themify(themes.$themes) {
            border-bottom: 1px solid themes.themed("secondary900");
          }
        }
      }
    }
  }
}