@use "../../../resources/scss/themes/themes";

@mixin linkColor($color) {
  &:after {
    @include themes.themify(themes.$themes) {
      background: themes.themed($color);
    }
  }
}

.fetura-link {
  cursor: pointer;
  //text-transform: uppercase;
  position: relative;
  display: inline-block;

  &:before,
  &:after {
    height: 2px;
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    left: 0;
  }

  &:after {
    transition: transform 200ms ease-in-out;
    transform: scaleX(0);
  }

  &:hover {
    &:after {
      transform: scaleX(1);
    }
  }

  &.success,
  &.primary {
    @include linkColor("primary500");
  }

  &.secondary {
    @include linkColor("secondary500");
  }

  &.info {
    @include linkColor("info500");
  }

  &.warning {
    @include linkColor("warning500");
  }

  &.error {
    @include linkColor("error500");
  }

  &.disabled {
    pointer-events: none;
  }
}