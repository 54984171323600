@use "../../resources/scss/themes/themes";
@use "../../resources/scss/utils/mixins";

.fallback-error {
  h4 {
    @include themes.themify(themes.$themes) {
      color: themes.themed('red500');
    }
  }

  min-height: 100vh;
  height: 0;
  background: url("../../resources/images/pigctures/farm-on-fire.svg") no-repeat bottom;
  background-size: cover;

  .fetura-button {
    &.redirect-button {
      margin: 0 0.25rem 0;
    }
  }

  @include themes.themify(themes.$themes) {
    background-color: themes.themed('blue50');
  }

  @include mixins.breakpoint(themes.$lg) {
    background-size: 100vw auto;
  }
}