@use "sass:math";

@use "normal-theme";
@use "dark-theme";
@use "base-colors";

//
//$darkColorThemeDesaturated: ();
//$darkColorBaseColors: ();
//
//@each $key, $value in dark-theme.$darkColorTheme{
//  dark-theme.$darkColorThemeDesaturated: map_merge(dark-theme.$darkColorThemeDesaturated, ($key: lighten($value, 10%)))
//}
//
//@each $key, $value in base-colors.$baseColors{
//  $darkColorBaseColors: map_merge($darkColorBaseColors, ($key: lighten($value, 10%)))
//}

$themes: (
  normal: map_merge(normal-theme.$normalColorTheme, base-colors.$baseColors),
  dark: map_merge(dark-theme.$darkColorTheme, base-colors.$baseColors),
  // contrast: map_merge($normalColorTheme, $baseColors),
);
// BREAKPOINTS
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1600px;

// HELPER WIDTH
$rightHelperDrawerWidth: 720px;

//RADIUS
$radiusSmall: 0.3rem, ;
$radiusBig: 0.5rem;

//padding
$paddingSmallHorizontal: 1rem;
$paddingSmallVertical: 0.25rem;
$paddingBigHorizontal: 1rem;
$paddingBigVertical: 1rem;


//easing
$easeStandard: cubic-bezier(0.4, 0.0, 0.2, 1);
$easeDecelerate: cubic-bezier(0.0, 0.0, 0.2, 1);
$easeAccelerate: cubic-bezier(0.4, 0.0, 1, 1);

//PADDING

@function themed($key) {
  @return map-get($theme-map, $key);
  // @return var(--#{$key});
}

@function getThemeName() {
  @return $theme-name;
}

@mixin mapVariables($map: "normal") {

  @each $key,
  $value in map-get($themes, $map) {
    --#{$key}: #{$value};
  }
}

@mixin baseColors() {

  @each $key,
  $value in base-colors.$baseColors {
    --#{$key}: #{$value};
  }
}

@mixin themify($themes: $themes) {

  @each $theme,
  $map in $themes {

    .theme-#{$theme} & {
      $theme-map: (
        ) !global;
      $theme-name: $theme !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}'
        );
      $theme-map: map-merge($theme-map, ($key: $value)) !global;
    }

    @content;
    $theme-map: null !global;
    $theme-name: null !global;
  }


}
}

@function brightness2($color) {
  //$color: get-color-by-variable($color);
  @return round((red($color) * 299) + (green($color) * 587) + (blue($color) * 114) * 0.001);
}

@function textContrast($color, $light: themed('textColorLight'), $dark: themed('textColorDark')) {

  @if $color ==null {
    @return null;
  }

  @else {
    $bright-indicator: abs(brightness2($color) - 40000);
    $light-text-ratio: (
      brightness2($light) * 0.5
    );
  $ratio: math.div($bright-indicator, $light-text-ratio);

  @if $ratio < 1 {
    @if $ratio >0.9 {
      @return lighten($light, 10%);
    }

    @return $light
  }

  @else {
    @if $ratio < 1.1 {
      @return darken($dark, 10%);
    }

    @return $dark;
  }
}
}