.table-cell-animal-location {
  display: flex;

  >:first-child {
    flex: 1 1 calc(100% - 1rem);
    max-width: calc(100% - 1rem);

    div {
      white-space: normal;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  >:last-child {
    flex: 0 0 1rem;
    text-align: center;
  }

  &.has-error {
    > :last-child {
      margin-right: 1.5rem;
    }
  }
}