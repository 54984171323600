@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";
@use "../../../resources/scss/themes/dark-theme";
@use "../../../resources/scss/themes/base-colors";
@use "../../../resources/scss/themes/normal-theme";

$mapColorsLight: (
        forest-dark: #4caf50,
        forest-light: #80e27e,
        water-dark: #2196f3,
        water-light: #6ec6ff,
        meadow: #ffffa8,
        grass: #bef67a,
        pig: #da819f,
        dispenser: #494949,
        trough: #757575,
        floor: #e0e0e0,
        steel-dark: #aba9a9,
        steel-light: #cbcaca,
        steel: #bdbcbc,
        building: #cfd8dc,
        asphalt: #3d3d3d,
        concrete: #eceff1,
        dirt: #efebe9,
        viewportal: #e8f5e9,
        plastic: map-get(normal-theme.$normalColorTheme, "primary500"),
        error: map-get(normal-theme.$normalColorTheme, "error500"),
        info: map-get(normal-theme.$normalColorTheme, "info500"),
        warning: map-get(normal-theme.$normalColorTheme, "warning500"),
        success: map-get(normal-theme.$normalColorTheme, "primary500"),
        supersuccess: map-get(base-colors.$baseColors, "white500")
);

@function darkify($keyNameOfLightThemeItem) {
  $mixWidthBG: mix(map-get(dark-theme.$darkColorTheme, "background100"), map-get($mapColorsLight, $keyNameOfLightThemeItem), 50%);
  @return mix(#2d2d2d, $mixWidthBG, 25%);
}


$mapColorsDark: (
        forest-dark: darkify("forest-dark"),
        forest-light: darkify("forest-light"),
        water-dark: darkify("water-dark"),
        water-light: darkify("water-light"),
        meadow: darkify("meadow"),
        grass: darkify("grass"),
        pig: darkify("pig"),
        dispenser: darkify("dispenser"),
        trough: darkify("trough"),
        floor: map-get(dark-theme.$darkColorTheme, "background200"),
        steel-dark: darkify("steel-dark"),
        steel-light: darkify("steel-light"),
        steel: darkify("steel"),
        building: map-get(dark-theme.$darkColorTheme, "background200"),
        asphalt: darkify("asphalt"),
        concrete: darkify("concrete"),
        dirt: darkify("dirt"),
        viewportal: mix(#01102d, mix(map-get(dark-theme.$darkColorTheme, "background100"), #bef67a, 50%), 70%),
        error: map-get(dark-theme.$darkColorTheme, "error500"),
        info: map-get(dark-theme.$darkColorTheme, "info500"),
        plastic: map-get(dark-theme.$darkColorTheme, "primary500"),
        warning: map-get(dark-theme.$darkColorTheme, "warning500"),
        success: map-get(dark-theme.$darkColorTheme, "primary500"),
        supersuccess: map-get(base-colors.$baseColors, "white500"),
);

$farmMapThemes: (
        normal: $mapColorsLight,
        dark: $mapColorsDark
);


.view-portal {
  @include themes.themify($farmMapThemes) {
    background: themes.themed("viewportal");
  }
}

svg {
  defs {
    @include themes.themify($farmMapThemes) {
      #ue-forest {
        .bg {
          fill: themes.themed("forest-light");
        }

        .fg {
          fill: themes.themed("forest-dark");
        }
      }

      #ue-water {
        .bg {
          fill: themes.themed("water-light");
        }

        .fg {
          fill: themes.themed("water-dark");
        }
      }

      #ue-meadow {
        > rect {
          fill: themes.themed("meadow");
        }
      }

      #ue-grassland {
        > rect {
          fill: themes.themed("grass");
        }
      }

      .def-pig {
        path {
          fill: themes.themed("pig");
        }

        &.info {
          path {
            fill: themes.themed("info");
          }
        }

        &.success {
          path {
            fill: themes.themed("success");
          }
        }

        &.feed-0 {
          .feed {
            fill: themes.themed("error");
          }
        }

        &.feed-1 {
          .feed {
            fill: themes.themed("warning");
          }
        }

        &.feed-2 {
          .feed {
            fill: themes.themed("success");
          }
        }

        &.feed-3 {
          .feed {
            fill: themes.themed("supersuccess");
          }
        }

        &.error {
          path {
            fill: themes.themed("error");
          }
        }

        &.warning {
          path {
            fill: themes.themed("warning");
          }
        }
      }

      .def-standing {


        .animal {
          fill: themes.themed("pig");
        }

        &.info {
          .stand {
            fill: themes.themed("info");
          }

          .trough {
            fill: themes.themed("info");
          }

          .animal {
            fill: themes.themed("info");
          }
        }

        &.success {
          .stand {
            fill: themes.themed("success");
          }

          .trough {
            fill: themes.themed("success");
          }

          .animal {
            fill: themes.themed("success");
          }
        }

        .stand {
          fill: themes.themed("steel-dark");
        }

        .device {
          fill: themes.themed("dispenser");
        }

        .bg {
          fill: themes.themed("floor");
        }

        .trough {
          fill: transparent;
        }

        &.feed-0 {
          .trough {
            fill: themes.themed("error");
          }
        }

        &.feed-1 {
          .trough {
            fill: themes.themed("warning");
          }
        }

        &.feed-2 {
          .trough {
            fill: themes.themed("success");
          }
        }

        &.feed-3 {
          .trough {
            fill: themes.themed("supersuccess");
          }
        }

        &.error {
          .stand {
            fill: themes.themed("error");
          }

          .trough {
            fill: themes.themed("error");
          }

          .animal {
            fill: themes.themed("error");
          }
        }

        &.warning {
          .stand {
            fill: themes.themed("warning");
          }

          .trough {
            fill: themes.themed("warning");
          }

          .animal {
            fill: themes.themed("warning");
          }
        }
      }

      #ue-dirt {
        > rect {
          fill: themes.themed("dirt");
        }
      }

      #ue-concrete {
        > rect {
          fill: themes.themed("concrete");
        }
      }

      #ue-asphalt {
        > rect {
          fill: themes.themed("asphalt");
        }
      }

      #ue-building {
        > rect {
          fill: themes.themed("building");
        }
      }

      .def-device {
        path {
          fill: transparent;
        }

        .device {
          fill: themes.themed("dispenser");
        }

        .trough {
          fill: themes.themed("trough");
        }

        .floor {
          fill: themes.themed("floor");
        }

        .steel {
          fill: themes.themed("steel");
        }

        .steel-light {
          fill: themes.themed("steel-light");
        }

        .steel-dark {
          fill: themes.themed("steel-dark");
        }

        .plastic {
          fill: themes.themed("plastic");
        }

        .door {
          fill: themes.themed("steel");
        }

        &.error {
          .steel-dark, .steel-light, .steel {
            fill: themes.themed("error");
          }
        }

        &.warning {
          .steel-dark, .steel-light, .steel {
            fill: themes.themed("warning");
          }
        }
      }

    }
  }
}

.farm-map-editor {

  #ue-standing,
  #ue-standing-small,
  #ue-standing-group {

    &-down,
    &-up,
    &-left,
    &-right {

      @include themes.themify(themes.$themes) {
        .stand {
          fill: #{themes.textContrast(themes.textContrast(themes.themed("background50")))}3F;
        }

        .bg {
          fill: transparent;
        }
      }

    }

  }

}