@use "../../../../resources/scss/themes/themes";

.table-cell-group {
  $padding: 1rem;
  padding-left: $padding;
  position: relative;
  height: 100%;

  &:before,
  &:after {
    position: absolute;
    content: "";
    left: 0;

    @include themes.themify(themes.$themes) {
      background: themes.themed("success500");
    }
  }

  &:before {
    width: 1px;
    height: 100%;
  }

  &:after {
    height: 1px;
    width: $padding;
    top: 50%;
    transform: translateY(-50%);
  }

  &.last {
    &:before {
      height: 50%;
    }
  }

  .remove-child {
    cursor: pointer;
    position: absolute;
    left: .10rem;
    transform: translate(-50%);
  }
}