@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";
@import "~@fortawesome/fontawesome-pro/scss/functions";
@import "~@fortawesome/fontawesome-pro/scss/variables";

.menu-container {
  z-index: 9999;
  touch-action: none;

  .cancel-button {
    display: none;
  }

  li {
    cursor: pointer;
    position: relative;

    .disable-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      pointer-events: none;
      font-weight: bolder;

      @include themes.themify(themes.$themes) {
        background: #{themes.textContrast(themes.themed("white500"))}0F;
        color: themes.textContrast(themes.themed("white500"))
      }
    }

    .fetura-button {

      width: 100% !important;
      margin: 0 !important;
      border-radius: 0;
      text-align: left;

      .fa-stack {
        font-size: .5rem;
      }
    }
  }

  // desktop
  &:not(.mobile) {
    position: absolute;
    top: 0;
    left: 0;
    @include mixins.shadow();
    $radius: themes.$radiusBig;
    overflow: hidden;
    opacity: 0;
    transition: opacity 500ms themes.$easeStandard;
    padding: 0.5rem 0;

    .fa-blank {
      width: 0;
      margin-right: -0.5rem !important;
    }

    &.enter-done {
      opacity: 1;
    }

    @include themes.themify(themes.$themes) {
      background: themes.themed("background50");
      border: 1px solid themes.themed("background200");
    }
  }

  // mobile
  &.mobile {
    .cancel-button {
      @include themes.themify(themes.$themes) {
        background: themes.themed("background50");
        border-top: 2px solid themes.themed("background200");
      }

      display: block;
      width: 100%;

      .fetura-button {
        padding-top: 1rem !important;
        padding-bottom: 2rem !important;
        width: 100%;
        text-align: left !important;
        margin: 0 !important;

      }
    }

    touch-action: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: transform 100ms ease-in-out;

    @keyframes openDrawer {
      from {
        transform: translateY(100%);
      }

      to {
        transform: translateY(0%);
      }
    }

    .menu {
      touch-action: none;
      overflow: hidden;
      transform: translateY(100%);
      border-radius: 1rem 1rem 0 0;
    }

    &.enter-done {
      .menu {
        animation: openDrawer 250ms normal forwards ease-in-out;
      }
    }

    .menu {
      padding-top: 1.5rem;
      z-index: 2;
      @include mixins.shadow(big);

      @include themes.themify(themes.$themes) {
        background: themes.themed("background50");
        border-top: 2px solid themes.themed("background200");
      }

      ul {
        max-height: 65vh;
        overflow: auto;
        touch-action: pan-y;
      }
    }

    &:before {
      position: absolute;
      content: " ";
      width: 100%;
      z-index: 1;
      height: 100%;
      opacity: 0.3;

      @include themes.themify(themes.$themes) {
        background: themes.textContrast(themes.themed("background50"));
      }
    }
  }


}

.mobile {
  .menu-container {
    .fetura-button {
      padding: 0.5rem 1rem !important;
      font-size: 1.3rem !important;
    }
  }

}