@use "../themes/themes";

@mixin breakpoint($point) {
  @media (max-width: $point) {
    @content;
  }
}

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1)+$replace (+str-replace(str-slice($string, $index + str-length($search)), $search, $replace));
  }

  @return $string;
}

@mixin breakpointWithHelper($point) {
  .helper-opened {
    @media (max-width: $point+themes.$rightHelperDrawerWidth) {
      @content;
    }
  }

  @media (max-width: $point) {
    @content;
  }
}

@mixin breakpointMinWithHelper($point) {
  .helper-opened {
    @media (min-width: 1+$point+themes.$rightHelperDrawerWidth) {
      @content;
    }
  }

  @media (min-width: 1+$point) {
    @content;
  }
}


@mixin after() {
  &:after {
    content: '';
    position: absolute;
    @content;
  }
}

@mixin before() {
  &:before {
    content: '';
    position: absolute;
    @content;
  }
}

@mixin rippleBackground($color) {
  background: $color radial-gradient(circle, transparent 1%, $color 1%) center/15000%;
}

@mixin shadow($shadow: small, $helper: false) {
  @if $shadow ==small {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  @else if $shadow ==table {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
  }

  @else if $helper ==true {
    box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.16);
  }

  @else {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 1.5rem 5px rgba(0, 0, 0, 0.12) !important;
  }
}

@mixin errorShadow($shadow: small) {
  @if $shadow ==small {
    box-shadow: 0 3px 6px rgba(253, 59, 96, 0.16), 0 3px 6px rgba(253, 59, 96, 0.23) !important;
  }

  @else if $shadow ==table {
    box-shadow: 0 3px 3px rgba(253, 59, 96, 0.16) !important;
  }

  @else {
    box-shadow: 0px 8px 10px -5px rgba(253, 59, 96, 0.2), 0px 16px 24px 2px rgba(253, 59, 96, 0.14), 0px 6px 1.5rem 5px rgba(253, 59, 96, 0.12) !important;
  }
}

@mixin noBreakWord($type: right) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @if $type ==left {
    direction: rtl;
    text-align: left;
  }
}

@mixin hideOnSmallDevices($point: themes.$xs) {
  @include breakpoint($point) {
    display: none !important;
  }
}

@mixin showOnSmallDevice($point: themes.$xs) {
  @include breakpoint($point) {
    display: block !important;
  }
}


@mixin backgroundAndColorByColor($color) {
  background: $color;

  @include themes.themify(themes.$themes) {
    color: themes.textContrast($color);
  }
}

@mixin triangle($direction, $sizeH, $sizeV, $color) {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  -moz-transform: scale(.9999);

  @if $direction ==top {
    border-width: 0 $sizeV $sizeH $sizeV;
    border-color: transparent transparent $color transparent;
  }

  @if $direction ==bottom {
    border-width: $sizeH $sizeV 0 $sizeV;
    border-color: $color transparent transparent transparent;
  }

  @if $direction ==left {
    border-width: $sizeV $sizeH $sizeV 0;
    border-color: transparent $color transparent transparent;
  }

  @if $direction ==right {
    border-width: $sizeV 0 $sizeV $sizeH;
    border-color: transparent transparent transparent $color;
  }

  @if $direction ==topright {
    border-width: 0 $sizeH $sizeV 0;
    border-color: transparent $color transparent transparent;
  }

  @if $direction ==bottomright {
    border-width: 0 0 $sizeH $sizeV;
    border-color: transparent transparent $color transparent;
  }

  @if $direction ==bottomleft {
    border-width: $sizeH 0 0 $sizeV;
    border-color: transparent transparent transparent $color;
  }

  @if $direction ==topleft {
    border-width: $sizeH $sizeV 0 0;
    border-color: $color transparent transparent transparent;
  }
}


@mixin hoverBigItem($helper: false) {
  transition: box-shadow 200ms ease-in-out, opacity 200ms ease-in-out;

  &:hover {
    @include shadow(big, $helper);
  }
}


@mixin badge() {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  min-width: 16px;
  font-weight: 700;
  padding: 3px;
  position: absolute;
  border-radius: 7px;
  bottom: -6px;
  right: -9px;

  @include themes.themify(themes.$themes) {
    background: themes.themed('error500');
  }
}

@mixin chamberColors() {
  $chamberTypes: "birth",
    "mating",
    "sows",
    "piglets",
    "building",
    "other";

  @include themes.themify(themes.$themes) {
    @each $chamber in $chamberTypes {
      &.#{$chamber} {
        background: themes.themed($chamber);
        color: themes.textContrast(themes.themed($chamber));
      }
    }
  }
}