@use "../../resources/scss/themes/themes";
@use "../../resources/scss/utils/mixins";

.modal-content {

  @include themes.themify(themes.$themes) {
    .loading {
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      top: 0 !important;
      background: themes.themed('background50') !important;
      color: themes.textContrast(themes.themed('background50'));

    }

    border: none;
    border-radius: themes.$radiusBig;
    background: themes.themed('background50');
    color: themes.textContrast(themes.themed('background50'));

    @include mixins.shadow();

    .modal-footer {
      border: 0;
      border-top: 2px solid themes.themed('background300');
      background: themes.themed('background50');
      color: themes.textContrast(themes.themed('background50'));
      position: relative;

      .fetura-button {
        margin-bottom: 0 !important;
      }

      $marginSize: themes.$paddingSmallVertical * 2;

      @include mixins.breakpoint(themes.$md) {
        display: flex;

        .fetura-button {
          margin: $marginSize * 0.5 !important;

          &:first-child:nth-last-child(1) {
            width: calc(50% - #{$marginSize});
          }

          &:first-child:nth-last-child(2),
          &:first-child:nth-last-child(2)~.fetura-button {
            width: calc(25% - #{$marginSize});
          }

          &:first-child:nth-last-child(3),
          &:first-child:nth-last-child(3)~.fetura-button {
            width: calc(33% - #{$marginSize});
          }

          &:first-child:nth-last-child(4),
          &:first-child:nth-last-child(4)~.fetura-button {
            width: calc(33% - #{$marginSize});
          }

        }
      }

      @include mixins.breakpoint(themes.$sm) {
        display: flex;

        .fetura-button {
          margin: $marginSize * 0.5 !important;


          &:first-child:nth-last-child(1) {
            width: calc(100% - #{$marginSize});
          }

          &:first-child:nth-last-child(2),
          &:first-child:nth-last-child(2)~.fetura-button {
            width: calc(50% - #{$marginSize});
          }

          &:first-child:nth-last-child(3),
          &:first-child:nth-last-child(3)~.fetura-button {
            width: calc(50% - #{$marginSize});

            &.primary {
              order: -3;
            }

            &:first-child {
              order: 3;
              width: calc(100% - #{$marginSize});
            }
          }

          &:first-child:nth-last-child(4),
          &:first-child:nth-last-child(4)~.fetura-button {
            width: calc(50% - #{$marginSize});

            &:nth-child(1) {
              order: 4;
            }

            &:nth-child(2) {
              order: 3;
            }

            &:nth-child(3) {
              order: 2;
            }

            &:nth-child(4) {
              order: 1;
            }
          }

        }
      }


      @include mixins.breakpoint(themes.$xs) {
        display: flex;
        flex-direction: column-reverse;

        .fetura-button {
          margin: $marginSize * 0.5 !important;
          order: initial !important;
          width: calc(100% - #{$marginSize}) !important;
        }
      }


    }


    .modal-header {
      border: 0;
      color: themes.themed('primary500');

      .fetura-button {
        margin: 0 !important;

        &.modal-exit {
          border-radius: 2rem;
          padding: 0;
          width: 2rem;
          height: 2rem;
        }
      }

      h4 {
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.modal {
  padding-left: 0 !important;
}