.maintenance-view {
  min-height: 100vh;
  height: 0;
  background: url("../../resources/images/pigctures/under-work.svg") no-repeat bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2, h3 {
    white-space: unset;
    justify-content: center;
  }

  .fetura-logo {
    width: 20rem;
  }
}
