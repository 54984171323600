@use "../../../../resources/scss/themes/themes";
@use "../../../../resources/scss/utils/mixins";

.fetura-farm-map__compass {
  @include themes.themify(themes.$themes) {
    background: themes.themed("background50");
  }

  $size: 48px;
  display: block;
  width: $size;
  height: $size;
  border-radius: 50%;
  position: relative;
  @include mixins.shadow(small);
  z-index: 1;

  .arrow {
    &:hover {
      opacity: 0.75 !important;
    }

    opacity: 0.25;

    &.curve-left {
      @include themes.themify(themes.$themes) {
        $color: themes.textContrast(themes.themed("background50"));
        left: 2px;
        top: 8px;
        border: 3px solid $color;
        border-color: transparent transparent transparent $color;
        height: 32px;
        width: 24px;
        border-radius: 48px 0 0 48px;
        position: absolute;

        &:after {
          position: absolute;
          transform: rotate(30deg);
          left: -1px;
          bottom: 0;
          @include mixins.triangle(top, 8px, 4px, $color);
        }
      }
    }

    &.curve-right {
      @include themes.themify(themes.$themes) {
        $color: themes.textContrast(themes.themed("background50"));
        right: 2px;
        top: 8px;
        border: 3px solid $color;
        border-color: transparent $color transparent transparent;
        height: 32px;
        width: 24px;
        border-radius: 0 48px 48px 0;
        position: absolute;

        &:after {
          position: absolute;
          transform: rotate(-30deg);
          right: -1px;
          bottom: 0;
          @include mixins.triangle(top, 8px, 4px, $color);
        }
      }
    }


  }

  .compass {
    &:hover {
      opacity: 1 !important;
    }

    z-index: 1;
    //transition: transform 500ms ease-in-out;
    width: 8px;
    height: 32px;
    position: absolute;
    background: transparent;
    top: 8px;
    left: 20px;
    opacity: 0.75;

    &:before {
      @include themes.themify(themes.$themes) {
        @include mixins.triangle(top, 16px, 4px, themes.themed("error500"));
      }

    }

    &:after {
      @include themes.themify(themes.$themes) {
        top: 16px;
        position: absolute;
        left: 0;
        opacity: 0.25;
        @include mixins.triangle(bottom, 16px, 4px, themes.textContrast(themes.themed("background50")));
      }
    }
  }
}