@use "../../../resources/scss/themes/themes";

.fetura-chip {
  display: flex;
  border-radius: 1rem;
  align-items: center;
  margin-right: 0.5rem;
  padding: 0.25rem;
  font-size: 1rem;

  &.sm {
    font-size: 0.8rem;
  }

  &.lg {
    font-size: 1.2rem;
  }

  .fetura-chip-content {
    padding: 0 themes.$paddingBigHorizontal;
  }

  .fetura-button {
    margin-bottom: 0;
    padding: 0;
  }

  @include themes.themify(themes.$themes) {
    background: themes.themed("background200");
    color: themes.textContrast(themes.themed("background200"));

    &.success,
    &.primary {
      background: themes.themed("primary500");
      color: themes.textContrast(themes.themed("primary500"));

      .fetura-button {
        background-color: themes.themed("primary700");
      }
    }

    &.secondary {
      background: themes.themed("secondary500");
      color: themes.textContrast(themes.themed("secondary500"));

      .fetura-button {
        background-color: themes.themed("secondary700");
      }
    }

    &.info {
      background: themes.themed("info500");
      color: themes.textContrast(themes.themed("info500"));

      .fetura-button {
        background-color: themes.themed("info700");
      }
    }

    &.warning {
      background: themes.themed("warning500");
      color: themes.textContrast(themes.themed("warning500"));

      .fetura-button {
        background-color: themes.themed("warning700");
      }
    }

    &.error {
      background: themes.themed("error500");
      color: themes.textContrast(themes.themed("error500"));

      .fetura-button {
        background-color: themes.themed("error700");
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 0.75;
  }
}