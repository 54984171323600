@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";

.theme-dark {
  .gauge-item {
    >svg {
      opacity: 0.9;
    }
  }
}

.gauge-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  >svg {
    width: 6.5em;
    overflow: visible;

    .gauge-background {
      @include themes.themify(themes.$themes) {
        fill: themes.themed("secondary100");
      }
    }

    >text {
      transition: opacity 200ms ease-in-out;

      @include themes.themify(themes.$themes) {
        fill: themes.textContrast(themes.themed("background50"));
      }

      &.hidden {
        opacity: 0;
      }

      &.visible {
        opacity: 1;
      }
    }
  }

  @include mixins.breakpoint(500px) {
    >svg {
      width: 6em;
    }
  }

  .gauge-text,
  .gauge-label {
    transition: color 200ms ease-in-out;
    text-align: center;
  }

  &-icon-container-checkbox {
    position: absolute !important;
    right: -0.4rem;
    top: -0.4rem;
    margin-bottom: 0 !important;

    .fetura-checkbox {
      label {
        &:before {
          width: 1.5rem !important;
          height: 1.5rem !important;
          font-size: 1rem !important;
        }
      }
    }
  }

  .gauge-legend {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .gauge-text {
    margin-top: 0.4em;
    font-size: 1.7rem;
    line-height: 1em;
    font-weight: 700;
    font-feature-settings: "zero", "tnum" 1;
  }

  .gauge-label {
    margin-top: 0.6em;
    line-height: 1.3em;
  }

  &.pointer {

    &:hover,
    &.selected {
      &:not(&.edit-mode):not(&.opacity-50) {

        .gauge-text,
        .gauge-label {
          @include themes.themify(themes.$themes) {
            color: themes.themed("primary500");
          }
        }
      }
    }
  }
}