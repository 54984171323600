.slide-in {
  &-enter {
    position: absolute;
    left: 100%;
    top: 0;
    width: 100%;

    &-active {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      transition: left 300ms;
    }

    &-done {
      position: inherit;
    }
  }

  &-exit {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    &-active {
      position: absolute;
      left: 100%;
      width: 100%;
      transition: left 300ms;
    }

    &-done {
      position: absolute;
      left: 100%;
      width: 100%;
    }
  }
}

.fade-in {
  &-appear {
    opacity: 0;

    &-active {
      opacity: 1;
      transition: opacity 250ms ease-in-out;

    }

    &-done {
      opacity: 1;
    }
  }
  &-enter {
   opacity: 0;

    &-active {
      opacity: 1;
      transition: opacity 250ms ease-in-out;

    }

    &-done {
      opacity: 1;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transition: opacity 250ms ease-in-out;

    }

    &-done {
      opacity: 0;
    }
  }
}
