@use "../../../../resources/scss/themes/themes";

.printable-fetura-grid {
  width: 100%;

  td,
  th {
    padding: themes.$paddingSmallVertical;
  }

  thead {
    tr {
      @include themes.themify(themes.$themes) {
        border-bottom: 4px solid themes.themed("primary500");
      }

      .empty {
        min-width: 5rem;
      }
    }
  }

  tbody {
    tr {
      @include themes.themify(themes.$themes) {
        border-bottom: 1px solid themes.themed("secondary500");
      }
    }
  }

  .bottom-pinned-container {
    @include themes.themify(themes.$themes) {
      border-top: 4px solid themes.themed("success500");
    }
  }

  td {

    &.success {
      @include themes.themify(themes.$themes) {
        color: themes.themed("success500");
      }
    }

    &.error {
      @include themes.themify(themes.$themes) {
        color: themes.themed("error500");
      }
    }
  }
}