@use "../../../resources/scss/themes/themes";

@media print {
  .printable-fetura-results-grid {
    &.break-page {
      page-break-before: always;
    }
  }

  @page {
    size: A4 portrait;
    margin: auto;
  }

  .printable-fetura-results-grid {
    width: 100%;
    font-size: 10px;

    td,
    th {
      padding: themes.$paddingSmallVertical;
    }

    th {
      width: 4rem;
      text-align: center;

      @include themes.themify(themes.$themes) {
        border-right: 1px solid themes.themed("primary500");
      }

      &.rotated {
        text-orientation: mixed;
        writing-mode: vertical-lr;
        white-space: nowrap;
        text-align: left;
        transform: rotate(180deg);
        padding-top: .5rem;
      }
    }

    thead {
      tr {
        @include themes.themify(themes.$themes) {
          border-bottom: 4px solid themes.themed("primary500");
        }
      }
    }

    tbody {
      tr {

        .parameter-row,
        .full-width-row {
          height: 2.5rem;
        }

        .full-width-row {
          text-transform: uppercase;
          font-weight: 700;
        }

        @include themes.themify(themes.$themes) {
          border-bottom: 1px solid themes.themed("primary500");
        }

        .parameter-row {
          @include themes.themify(themes.$themes) {
            border-right: 1px solid themes.themed("primary500");
          }
        }
      }
    }
  }
}