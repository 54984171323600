@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";
@use "../../../resources/scss/themes/dark-theme";

div {
  &:not(.fetura-select) {
    > .fetura-input {

      input,
      textarea {
        &::placeholder {
          opacity: 0.5;
        }
      }
    }
  }
}

.form-for-events {
  .fetura-input {
    border-radius: 0 !important;
  }
}

.fetura-input {
  @include themes.themify(themes.$themes) {
    $disabledColor: themes.themed('disabled300');
    $disabledColorLight: themes.themed('disabled100');
    $bgColorLight: themes.themed('background100');
    $bgColor: themes.themed('background300');
    $bgColorDark: themes.themed('background500');
    $primaryColor: themes.themed('primary500');
    $errorColor: themes.themed('error500');
    $errorColorLight: themes.themed('error300');
    $errorColorDark: themes.themed('error700');
    $textColorLight: themes.themed('textColorLight');
    $infoColor: themes.themed('info500');
    $infoColorLight: themes.themed('info300');
    $warningColor: themes.themed('warning500');
    $warningColorLight: themes.themed('warning300');
    $warningColorDark: themes.themed('warning700');
    position: relative;
    // zwiekszone z 1rem na 1.4rem zeby zmiescic error dwulinijkowy
    margin: 0 0 1.4rem;
    border-radius: 0.3rem 0.3rem 0 0;

    input {
      -webkit-appearance: none !important;
    }

    &.error,
    &.warning {
      @include mixins.after() {
        top: 100%;
        left: 0;
        right: 0;
        content: attr(data-tip);
        font-size: 0.7rem;
        display: inline-block;
        // wyswietlanie 2 linijek a potem kropki
        line-height: 0.8rem;
        max-height: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    input,
    textarea {
      width: 100%;
      border: none;
      //font-size: 1rem;
      padding: 0.25rem;
      line-height: 1.618;
      //border-radius: 0.3rem 0.3rem 0 0;
      border-radius: inherit;
      background: $bgColorLight;
      color: themes.textContrast($bgColorLight);
      border-bottom: 2px solid $bgColor;
      transition: border-bottom-color 200ms ease-in;

      &:hover {
        border-bottom-color: $bgColorDark;
      }

      &:disabled {
        background: $disabledColorLight;
        color: themes.textContrast($disabledColorLight);
        cursor: not-allowed;
        border-bottom-color: $disabledColor;
      }

      &:focus {
        outline: none;
        border-bottom-color: $infoColor !important;
      }

      &:read-only {
        border-bottom-color: $bgColorLight !important;
      }

      &::placeholder {
        color: themes.textContrast($bgColorLight);
      }
    }

    input,
    textarea {
      &:invalid {
        border-bottom-color: $errorColor !important;
      }
    }

    &.error,
    &.warning {
      span + .input-icon {
        margin-right: 50px;
      }
    }

    &.error {

      input,
      textarea {
        border-bottom-color: $errorColor !important;
      }

      &:after {
        color: $errorColor;
      }
    }

    &.warning {

      input,
      textarea {
        border-bottom-color: $warningColor !important;
      }

      &:after {
        color: $warningColor;
      }
    }
  }

  //&.error {
  //  .input-icon {
  //    right: 3rem;
  //  }
  //}

  .input-icon {
    position: absolute;
    right: 0;
    top: 0;

    &.error {
      @include themes.themify(themes.$themes) {
        @include mixins.triangle(topright, .8rem, .7rem, themes.themed("error500"));
      }
    }

    &.warning {
      @include themes.themify(themes.$themes) {
        @include mixins.triangle(topright, .8rem, .7rem, themes.themed("warning500"));
      }
    }
  }

  &.unit {

    input,
    textarea {
      padding-right: 55px;
    }

    // wyłczyłem bo byl podwójnie przesunięty przez margin-right oraz right np. widok edycji paszy skladniki
    //.input-icon {
    //  right: 2rem;
    //}
  }

  span {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    padding: themes.$paddingSmallVertical 0.5rem;
    border-radius: 0 themes.$radiusSmall 0 0;

    @include themes.themify(themes.$themes) {
      border-bottom: 2px solid themes.themed("background300");
      background: themes.themed("background300");
      color: themes.textContrast(themes.themed("background300"))
    }
  }

  &.make-room-for-button {
    width: calc(100% - 2rem) !important;
    float: left;

    > input,
    > textarea {
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    &.fetura-input + .fetura-button {
      float: right;
      height: inherit !important;
      border-bottom: 2px solid transparent;
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
      margin-left: 0;
    }

    span {
      border-radius: 0 !important;
    }

  }
}

.theme-dark {
  input {
    color-scheme: dark;
  }

  input::-webkit-calendar-picker-indicator {
    filter: brightness(0.8);
  }

  .fetura-input {
    &:before {
      pointer-events: none;
      content: ' ';
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: inherit;
    }

    &:not(.file) {
      &:before {
        box-shadow: 0 0 2px 0 map-get(dark-theme.$darkColorTheme, "textColorLight");
      }
    }
  }
}

label {
  .fetura-input {
    margin: 0 !important;
    width: 200px;
    float: right;
    font-size: 1rem;

    input {
      border-bottom: none !important;
      border-radius: 0.3rem !important;
      text-align: right;
      font-weight: 500;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      @include themes.themify(themes.$themes) {
        background: themes.themed("background300");
        color: themes.textContrast(themes.themed("background300"))
      }
    }
  }
}