.heats {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .color-square {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin: .3rem .5rem;
    transition: transform 200ms ease-in-out;
    justify-content: center;
    align-items: center;

    &.highlight {
      transform: scale(1.7);
    }

    &.empty {
      border: 1px solid;
    }

    i {
      font-size: .8rem;
    }

    &.large {
      width: 2rem;
      height: 2rem;
    }
  }
}