@use "../resources/scss/themes/themes";
@use "../resources/scss/utils/mixins";

.no-role-component {
  h4 {
    @include themes.themify(themes.$themes) {
      color: themes.themed('red500');
    }
  }

  .fetura-button {
    margin: 0 0.25rem 0;
  }

  min-height: 100vh;
  height: 0;
  background: url("../resources/images/pigctures/farm-forbidden.svg") no-repeat bottom;
  background-size: cover;

  @include themes.themify(themes.$themes) {
    background-color: themes.themed('blue50');
  }
}

@include mixins.breakpointWithHelper(themes.$lg) {
  .no-role-component {
    background-size: 100vw auto;
  }
}

// show plain background when has no access to sub-route
.settings-view-container-main {
  .no-role-component {
    min-height: 0;

    @include themes.themify(themes.$themes) {
      background: themes.themed("background500");
    }

    .fetura-card {
      margin-top: 2rem;
    }
  }
}