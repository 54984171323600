@use "../../../resources/scss/utils/mixins";

.fetura-picture-in-picture {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  video {
    width: 400px;
    transition: width 200ms ease-in-out;
  }

  &.expanded {
    video {
      width: 1000px;
    }
  }

  &-close {
    position: absolute;
    top: .5rem;
    right: 1rem;
    cursor: pointer;
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: .6;
    }
  }

  @include mixins.shadow(big);
}