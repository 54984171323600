.time-counter {
  display: flex;
  justify-content: center;
  white-space: normal;

  &-item {
    text-align: center;
    position: relative;

    &:not(:last-child) {
      margin-right: 1.5rem;

      &:after {
        content: ":";
        position: absolute;
        right: -1rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    > :last-child {
      font-size: .8rem;
      font-weight: normal;
      text-transform: uppercase;
    }
  }
}
