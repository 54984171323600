@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";
@use "../../../resources/scss/themes/dark-theme";

.fetura-card {
  &.deletion-mode {
    @include mixins.errorShadow();
  }
}

.sow-card-header {
  display: flex;
  justify-content: space-between;

  .fetura-text-switch {
    width: calc(16rem + .75rem);
    margin-left: auto;
  }
}

.sow-card {
  .cycle-grid-container {
    overflow: auto;

    .cycle-grid {
      display: inline-grid;
      grid-template-columns: repeat(16, auto);
      width: max-content;
      min-width: 100%;

      .cycle-grid-header {
        font-weight: 700;
        padding: themes.$paddingSmallVertical;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;

        @include themes.themify(themes.$themes) {
          border-right: 1px dashed themes.themed("primary500");
        }

        &:not(.has-children) {
          grid-row: 1 / span 2;

          @include themes.themify(themes.$themes) {
            border-bottom: 4px solid themes.themed("primary500");
          }
        }

        &.has-children {
          @include themes.themify(themes.$themes) {
            border-bottom: 1px dashed themes.themed("primary500");
          }

          &.last-main-header {
            border-right: none;
          }
        }

        &.last-header {
          border-right: none;
        }
      }

      .cycle-grid-content {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        //padding: themes.$paddingSmallVertical;
        flex-direction: column;
        position: relative;

        .cell-wrapper {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-width: 100%;
          height: 100%;
          padding: themes.$paddingSmallVertical;
          transition: background-color 200ms ease-in-out, opacity 200ms ease-in-out;

          &.no-pointer-events {
            pointer-events: none;
          }

          &:hover {
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;

            &.success {
              @include themes.themify(themes.$themes) {
                background-color: rgba(themes.themed("success500"), .2) !important;
              }
            }
          }

          &.error {
            @include themes.themify(themes.$themes) {
              background-color: rgba(themes.themed("error500"), .2) !important;
            }
          }
        }

        &.error {
          @include themes.themify(themes.$themes) {
            background: themes.themed("error500");
            color: themes.textContrast(themes.themed("error500"));
          }
        }

        &.warning {
          @include themes.themify(themes.$themes) {
            background: themes.themed("warning500");
            color: themes.textContrast(themes.themed("warning500"));
          }
        }

        @include themes.themify(themes.$themes) {
          border-bottom: 1px solid themes.themed("secondary500");
          border-right: 1px dashed themes.themed("secondary500");
        }

        .fa-check {
          @include themes.themify(themes.$themes) {
            color: themes.themed("success500");
          }
        }

        .fa-times {
          @include themes.themify(themes.$themes) {
            color: themes.themed("error500");
          }
        }

        .fa-redo-alt {
          @include themes.themify(themes.$themes) {
            color: themes.themed("warning500");
          }
        }

        i {
          &:not(:first-child) {
            margin-left: 1rem;
          }
        }

        &.last-cell {
          border-right: none;
        }

        &.normal-display-none {
          display: none;
        }

        &.print-display-none-error {
          user-select: none;
          -moz-user-select: none;
          transition: box-shadow 200ms ease-in-out, opacity 200ms ease-in-out;

          @include themes.themify(themes.$themes) {
            background: themes.themed("error500");
            color: themes.textContrast(themes.themed("error500"));
          }

          .fa-times,
          .fa-check,
          .fa-redo-alt {
            @include themes.themify(themes.$themes) {
              color: themes.textContrast(themes.themed("error500"));
            }
          }

          &.show {
            &:hover {
              cursor: pointer;
            }
          }
        }

        .box {
          position: absolute;
          right: 0;
          width: 0;
          height: 0;
          border-width: 0 .6rem .6rem 0;
          border-style: solid;

          &.error {
            top: 0;

            @include themes.themify(themes.$themes) {
              border-color: transparent themes.themed("error500") transparent transparent;
            }
          }

          &.success {
            top: 0;

            @include themes.themify(themes.$themes) {
              border-color: transparent themes.themed("success500") transparent transparent;
            }
          }

          &.warning {
            top: 0;

            @include themes.themify(themes.$themes) {
              border-color: transparent themes.themed("warning500") transparent transparent;
            }
          }

          &.info {
            top: calc(100% - .6rem);
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);

            @include themes.themify(themes.$themes) {
              border-color: transparent themes.themed("info500") transparent transparent;
            }
          }
        }

        &.expected-date {
          @include themes.themify(themes.$themes) {
            color: themes.themed("info500");
          }
        }

        &.is-interrupted {
          @include themes.themify(themes.$themes) {
            $bgLight: #{themes.themed("background600")}1D;
            $bgDark: #{themes.themed("background900")}1D;
            background: repeating-linear-gradient(45deg, $bgLight, $bgLight 10px, $bgDark 10px, $bgDark 20px);
          }
        }
      }
    }
  }
}

.theme-dark {
  .sow-card {
    .cycle-grid-container {
      .cycle-grid {
        .cycle-grid-content {
          &.is-interrupted {
            $bgLight: #{map-get(dark-theme.$darkColorTheme, "background50")}1D;
            $bgDark: #{map-get(dark-theme.$darkColorTheme, "background900")}1D;
            background: repeating-linear-gradient(45deg, $bgLight, $bgLight 10px, $bgDark 10px, $bgDark 20px);
          }
        }
      }
    }
  }
}