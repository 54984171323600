@use "../../../../resources/scss/themes/themes";
@use "../../../../resources/scss/utils/mixins";
@use "../../../../resources/scss/utils/variables";

.event-popup {
  max-width: 400px;
  @include themes.themify(themes.$themes) {
    &.left {
      &:before {
        content: "";
        position: absolute;
        left: -10px;
        top: var(--arrow-top);
        width: 0;
        height: 0;
        border-right: 10px solid themes.themed("success500");
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        clear: both;
      }
    }

    &.right {
      &:before {
        content: "";
        position: absolute;
        right: -10px;
        top: var(--arrow-top);
        width: 0;
        height: 0;
        border-left: 10px solid themes.themed("success500");
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        clear: both;
      }
    }

    $bgColor: themes.themed("background50");
    background: $bgColor;
    color: themes.textContrast($bgColor);

    &.left {
      border-radius: 0 themes.$radiusBig themes.$radiusBig themes.$radiusBig;
    }

    &.right {
      border-radius: themes.$radiusBig 0 themes.$radiusBig themes.$radiusBig;
    }

    padding: .5rem .5rem .5rem .5rem;
    width: fit-content;
    min-height: fit-content !important;
    margin-bottom: 2rem;
    z-index: 999;
    //position: absolute;
    position: fixed;
    @include mixins.shadow();

    .col {
      display: flex;

      .fetura-button-group,
      .fetura-input {
        margin: 0 !important;

        .input-icon {
          margin-right: 0 !important;
        }
      }
    }
  }
}