@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";

.fetura-select {
  position: relative;
  width: 100%;

  input {
    -webkit-appearance: none !important;

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      -webkit-appearance: none;
      appearance: none;
    }
  }

  .input-icon {
    position: absolute;
    right: 0;
    top: 0;

    &.error {
      @include themes.themify(themes.$themes) {
        @include mixins.triangle(topright, .8rem, .7rem, themes.themed("error500"));
      }
    }

    &.warning {
      @include themes.themify(themes.$themes) {
        @include mixins.triangle(topright, .8rem, .7rem, themes.themed("warning500"));
      }
    }
  }

  select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 0;
    -webkit-appearance: none;
  }

  .fetura-select-icons {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem;

    &.clear-button {
      right: 2rem;
    }
  }

  &-custom-placeholder {
    position: absolute;
    left: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-weight: 400;
  }
}

.select-menu {
  position: absolute;
  z-index: 9998;
  max-height: 10rem;
  overflow: auto;
  pointer-events: all !important;
  @include mixins.shadow();

  @include themes.themify(themes.$themes) {
    background: themes.themed("background50");
    color: themes.textContrast(themes.themed("background50"));
  }

  .select-item {
    padding: themes.$paddingSmallVertical;
    cursor: pointer;

    &.hovered {
      @include themes.themify(themes.$themes) {
        background: themes.themed("background300");
      }
    }

    &.active {
      font-weight: 700;
    }
  }

  &.mobile {
    position: fixed;
    max-height: unset;
    height: 100%;
    width: 100%;
    top: 14px;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .scroll-component {
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: 100%;
    }
  }

  &.static {
    width: 100%;
    top: 100%;
  }
}