@use "../../../resources/scss/themes/themes";

.fetura-mobile-info {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100%;
  transition: left 300ms ease-in-out;
  z-index: 999;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  @include themes.themify(themes.$themes) {
    background: themes.themed("background50");
  }

  &.show {
    left: 0;
  }

  .fetura-mobile-info-header {
    position: relative;
    padding: .5rem 0;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;

    @include themes.themify(themes.$themes) {
      border-bottom: 1px solid themes.themed("secondary500");
    }

    h4 {
      text-align: center;
      width: 80%;
      flex-wrap: wrap;
      white-space: normal;
      justify-content: center;
    }

    i {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.5rem;
    }
  }
}