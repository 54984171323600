@use "../../../resources/scss/themes/themes";

.video-player-progress {
  position: relative;
  height: .3rem;
  transition: height 200ms ease-in-out;

  .progress {
    width: 100%;
    height: 100%;
    border-radius: 0;
    appearance: none;
    display: block;
    cursor: pointer;

    @include themes.themify(themes.$themes) {
      background: themes.themed("background400");
    }

    .progress-bar {
      height: 100%;
      transition: none;
      position: absolute;
      top: 0;
      left: 0;

      @include themes.themify(themes.$themes) {
        background: themes.themed("primary500");
      }
    }
  }

  &-shadow {
    width: 0;
    height: 100%;
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    mix-blend-mode: color-dodge;

    @include themes.themify(themes.$themes) {
      background: themes.themed("primary900");
    }
  }

  .tooltip {
    bottom: 100%;
    display: none;
  }

  &:hover {
    height: .5rem;

    .video-player-progress-shadow {
      display: block;
    }

    .tooltip {
      display: block;
    }
  }
}