@use "../../../resources/scss/utils/mixins";
@use "../../../resources/scss/themes/themes";
@import "~@fortawesome/fontawesome-pro/scss/functions";
@import "~@fortawesome/fontawesome-pro/scss/variables";

.fetura-radio {
  @include themes.themify(themes.$themes) {
    position: relative;
    display: inline-block;
    margin: 0 0.25rem 1rem;
    $disabledColor: themes.themed('disabled300');
    $disabledColorLight: themes.themed('disabled100');
    $bgColorLight: themes.themed('background300');
    $primaryColor: themes.themed('primary500');

    //&:last-child {
    //  margin: 0 0 1rem;
    //}
    input[type="radio"] {
      display: none;
      cursor: pointer;

      &~label {
        @include mixins.before() {
          cursor: pointer;
          position: unset;
          margin-right: 5px;
          border-radius: 50%;
          display: inline-block;
          width: 1.3333rem;
          height: 1.3333rem;
          padding: themes.$paddingSmallVertical;
          font: normal normal normal 0.7777rem "Font Awesome 6 Pro";
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: fa-content($fa-var-check);
          text-align: center;
          color: transparent;
          transition: background 200ms ease-in-out, color 200ms ease-in-out;
          background: $bgColorLight;
        }
      }

      &:checked {
        &~label {
          @include mixins.before() {
            text-align: center;
            position: unset;
            content: fa-content($fa-var-check);
            background: $primaryColor;
            color: themes.textContrast($primaryColor);
          }
        }
      }

      &:disabled {
        &~label {
          cursor: not-allowed;

          &:before {
            cursor: not-allowed;
            background: $disabledColor;
          }
        }
      }
    }
  }
}