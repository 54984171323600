@use "../../../../resources/scss/themes/themes";

.table-grid-filter {


  &-buttons {
    display: flex;
    justify-content: space-between;

    &-main {
      display: flex;
      flex: 1 1 0;

      .fetura-button {
        min-width: 2rem !important;
      }

      .fetura-input {
        margin-left: 1rem;
        width: 100%;
      }
    }
  }

  &-container {
    overflow-x: auto;

    @include themes.themify(themes.$themes) {
      border-top: 1px solid themes.themed("secondary500");
    }
  }

  &-advanced {

    //min-width: 600px;
    .fetura-button {
      min-width: 3rem;
    }

    .fetura-select {
      min-width: 12rem;
    }

    .fetura-input {
      min-width: 8rem;
    }

    position: relative;

    &-apply {
      position: absolute;
      right: 0;
      bottom: 0;

      .fetura-button {
        margin-left: .5rem;
        margin-bottom: 0;
      }
    }


  }

}