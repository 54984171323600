@use "sass:math";

@use "../../../resources/scss/themes/themes";
@use "../../../resources/scss/utils/mixins";
@use "../../../resources/scss/themes/dark-theme";

.fetura-text-switch {
  font-size: 1rem;
  position: relative;
  height: 2rem;
  border-radius: 2rem;

  @include themes.themify(themes.$themes) {
    &:after {
      content: ' ';
      position: absolute;
      height: 100%;
      border-radius: 2rem;
      background: themes.themed("primary500");
      @include mixins.shadow();
      transition: all 320ms themes.$easeDecelerate;
    }

    @for $i from 1 through 12 {
      &.size-#{$i} {
        .item {
          width: #{math.div(100%, $i)};
        }

        &:after {
          width: #{math.div(100%, $i)};
        }

      }

      &.pos-#{$i} {
        &:after {
          transform: translateX(100% * $i);
        }
      }
    }

    display: flex;
    background: themes.themed("background100");

    .item {
      text-align: center;
      z-index: 1;
      padding: 0.25rem 0.25rem;
      color: themes.textContrast(themes.themed("background100"));
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.selected {
        color: themes.textContrast(themes.themed("primary500"));
      }

      &.disabled {
        opacity: .4;
        cursor: not-allowed;
      }
    }

    &.disabled {
      &>.item {
        cursor: not-allowed;
      }

      &:after {
        background-color: themes.themed("disabled300");
      }

      .item {
        &.selected {
          color: themes.textContrast(themes.themed("disabled300"));
        }
      }
    }
  }
}

.theme-dark {
  .fetura-text-switch {
    &:not(.primary):not(.secondary):not(.success):not(.info):not(.warning):not(.error):not(.white):not(.text):not(.bordered) {
      &:before {
        pointer-events: none;
        content: ' ';
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        position: absolute;
        border-radius: inherit;
        box-shadow: 0 0 2px 0 map-get(dark-theme.$darkColorTheme, "textColorLight");
      }
    }
  }
}