@use "../../../resources/scss/themes/themes";

.technology-group-select {
  position: relative;
  height: 100%;
  width: 100%;

  &-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    font-size: 1.2rem;

    @include themes.themify(themes.$themes) {
      background: themes.themed("background200");
    }
  }

  &-loading-small {
    position: absolute;
    right: 3.5rem;
    //height: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}