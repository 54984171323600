@use "sass:math";

.loading-language-swapper {
  position: relative;
  background: inherit;

  > div {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    white-space: nowrap;
    background: inherit;
    transition: all;
    opacity: 0;
  }

  // przy dodaniu tlumaczenia zmienic ilosc
  $elements: 5;
  @for $i from 1 through $elements {

    @keyframes text-swap#{$i} {
      $percent: math.div(100%, $elements);
      $min: $percent * ($i - 1);
      $max: $percent * $i;
      0%, #{$min}, #{$max}, 100%{
        opacity: 0;
      }
      #{$max - math.div($percent, 2)} {
        opacity: 1;
      }
    }

    > div:nth-child(#{$i}) {
      animation: text-swap#{$i} #{$elements + 1}s ease-in-out infinite;
    }
  }
}
