@use "../../../../resources/scss/themes/themes";
@use "../../../../resources/scss/utils/mixins";

.fetura-farm-map {
  &__zoom {
    z-index: 1;
    border-radius: 2rem;
    overflow: hidden;
    @include mixins.shadow(small);

  }
}