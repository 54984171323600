@use "../../../resources/scss/themes/themes";

.json-to-table {
  .no-border {
    border: none !important;

    * {
      border: none !important;
    }
  }

  @include themes.themify(themes.$themes) {

    td,
    th {
      padding: 5px;
      border: 1px solid themes.themed('background300');
    }

    th {
      font-weight: 600;
      background: themes.themed('background300');
      opacity: 0.75;
    }

    td {
      text-align: left;
    }

    tr {
      //&:nth-child(even){
      background-color: themes.themed('background50');

      //}
      &:nth-child(odd) {
        background-color: themes.themed('background100');
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      font-family: sans-serif;
      font-size: 0.8rem;
    }
  }

  &.error {
    @include themes.themify(themes.$themes) {

      td,
      th {
        border: 1px solid themes.themed('error300');
        color: themes.textContrast(themes.themed("error300"))
      }

      th {
        background: themes.themed('error300');
        color: themes.textContrast(themes.themed("error300"))
      }

      tr {
        background-color: themes.themed('error50');

        &:nth-child(odd) {
          background-color: themes.themed('error100');
        }
      }
    }
  }
}

.json-to-table--transparent {
  overflow: hidden;
  @include themes.themify(themes.$themes) {
    tr {
      background-color: transparent !important;

      &:not(:last-child) {
        td,
        th {
          border-bottom: 1px dashed themes.themed('background500');
        }
      }
    }
    td,
    th {
      border: none;


    }

    th {
      background: transparent;
      opacity: 1;
    }
  }
}