@use "../../../../../resources/scss/themes/themes";
@use "../../../../../resources/scss/utils/mixins";
@use "../../../../../resources/scss/themes/dark-theme";

@mixin helperNoteStyle($baseColorName) {
    @include themes.themify(themes.$themes) {
        background: themes.themed($baseColorName + "300");
        border: 3px solid themes.themed($baseColorName + "500");
        border-radius: themes.$radiusBig;
        color: themes.textContrast(themes.themed($baseColorName + "500"));

        a {
            color: themes.textContrast(themes.themed($baseColorName + "500"));
        }
    }
}

@mixin helperNoteStyleDark($baseColorName) {
    background: map-get(dark-theme.$darkColorTheme, $baseColorName + "500");
    border: 3px solid
        map-get(dark-theme.$darkColorTheme, $baseColorName + "600");
}

.fetura-helper-note {
    padding: 0.5rem;
    margin: 0.5rem 0 0.5rem 0;

    &.info {
        @include helperNoteStyle("info");
    }

    &.success {
        @include helperNoteStyle("primary");
    }

    &.error {
        @include helperNoteStyle("error");
    }

    &.warning {
        @include helperNoteStyle("warning");
    }

    &.primary {
        @include helperNoteStyle("primary");
    }

    &.secondary {
        @include helperNoteStyle("secondary");
    }
}

.theme-dark {
    .fetura-helper-note {
        &.info {
            @include helperNoteStyleDark("info");
        }

        &.success {
            @include helperNoteStyleDark("primary");
        }

        &.error {
            @include helperNoteStyleDark("error");
        }

        &.warning {
            @include helperNoteStyleDark("warning");
        }

        &.primary {
            @include helperNoteStyleDark("primary");
        }

        &.secondary {
            @include helperNoteStyleDark("secondary");
        }
    }
}
