@use "../../../../resources/scss/utils/mixins";
@use "../../../../resources/scss/themes/themes";

.treatment {
  .medicine-item {
    display: inline-block;
    margin-right: 0.5rem;
    @include mixins.shadow();
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    .medicine-text {
      padding: 0.5rem 1rem;
    }

    .color {
      height: 0.4rem;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      @include themes.themify(themes.$themes) {
        background: themes.themed("secondary400");
        color: themes.textContrast(themes.themed("secondary400"));
      }
    }

    &.selected {
      @include themes.themify(themes.$themes) {
        background: themes.themed("primary500");
        color: themes.textContrast(themes.themed("primary500"));
      }
    }
  }

  .error-item {
    position: absolute;
    bottom: -.4rem;
    font-size: 0.7rem;
    display: inline-block;
    line-height: 0.9rem;
    max-height: 1.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @include themes.themify(themes.$themes) {
      color: themes.themed("error500");
    }
  }
}