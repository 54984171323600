@use "../../../../resources/scss/themes/themes";
@use "../../../../resources/scss/utils/mixins";

.grid-wrapper {
  display: grid;
  overflow: auto;
  width: 100%;

  >div {
    min-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;

    @include themes.themify(themes.$themes) {
      border-right: 1px solid themes.themed("success500");
      border-bottom: 1px solid themes.themed("success500");
    }

    &.last-item {
      border-right: none;
    }

    &.header {
      font-weight: 500;
    }

    &.summary {
      font-weight: 700;
    }

    &.animal-number {
      min-width: 3rem;
    }

    &.no-age,
    &.success {
      position: relative;

      .mixins.triangle-icon {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    &.success {
      @include themes.themify(themes.$themes) {
        background-color: themes.themed("success100");
        color: themes.textContrast(themes.themed("success100"));

        .mixins.triangle-icon {
          @include mixins.triangle(topright, .8rem, .7rem, themes.themed("success500"))
        }
      }
    }

    &.no-age {
      .mixins.triangle-icon {
        @include themes.themify(themes.$themes) {
          @include mixins.triangle(topright, .8rem, .7rem, themes.themed("error500"))
        }
      }
    }
  }
}