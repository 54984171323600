@use "../../../resources/scss/utils/mixins";
@use "../../../resources/scss/themes/themes";
@import "~@fortawesome/fontawesome-pro/scss/functions";
@import "~@fortawesome/fontawesome-pro/scss/variables";

.fetura-checkbox {
  @include themes.themify(themes.$themes) {
    position: relative;
    display: inline-block;
    max-width: 100%;
    margin: 0 0.25rem 1rem;
    $disabledColor: themes.themed('disabled300');
    $disabledColorLight: themes.themed('disabled100');
    $bgColorLight: themes.themed('background400');
    $primaryColor: themes.themed('primary500');

    //&:last-child {
    //  margin: 0 0 1rem;
    //}
    input[type='checkbox'] {
      display: none;
      cursor: pointer;

      & ~ label {
        display: inline;

        .checkbox {
          vertical-align: text-top;
          position: relative;
          display: inline-block;
          cursor: pointer;
          margin-right: 5px;
          border-radius: themes.$radiusSmall;
          width: 1.4rem;
          height: 1.4rem;
          color: transparent;
          transition: background 200ms ease-in-out, color 200ms ease-in-out;
          background: $bgColorLight;
          overflow: hidden;

          &:before {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font: normal normal normal 0.7rem "Font Awesome 6 Pro";
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: fa-content($fa-var-check);
          }
        }
      }

      &:checked {
        & ~ label {
          .checkbox {
            content: fa-content($fa-var-check);
            background: $primaryColor;
            color: themes.textContrast($primaryColor);
          }
        }
      }

      &:disabled {
        & ~ label {
          cursor: not-allowed;

          .checkbox {
            cursor: not-allowed;
            opacity: 0.38 !important;
          }
        }
      }


    }


    // todo: zrobic animacje ładowania checkboxa
    &.is-loading {
      input[type='checkbox'] {
        pointer-events: none;

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        & ~ label {
          .checkbox {
            &:before {
              content: fa-content($fa-var-circle-notch);
              color: themes.textContrast(themes.themed("background500"));
              animation: spin 3s linear infinite;
            }
          }
        }
      }
    }

    &.margin-none {
      margin: 0 0.25rem;
    }
  }

  &.reversed {
    label {
      display: inline-flex !important;
      flex-direction: row-reverse;

      .checkbox {
        margin-left: 5px;
        margin-right: unset !important;
        flex-shrink: 0;
      }
    }
  }
}