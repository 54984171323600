@use "../../../resources/scss/themes/themes";
@use "sass:math";

.fattening-progress {
    width: 100%;
    display: flex;
    position: relative;
    padding-bottom: 1rem;

    &-item {
        height: 100%;
    }

    &-container {
        height: 2rem;
        display: flex;
        position: relative;

        @include themes.themify(themes.$themes) {
            background: themes.themed("secondary500");
        }

        &:not(:first-child) {
            @include themes.themify(themes.$themes) {
                border-left: 1px solid themes.themed("secondary900");
            }
        }

        &.bordered {
            .fattening-progress-item {

                &:only-child,
                &:not(:only-child):last-child {
                    @include themes.themify(themes.$themes) {
                        border-right: 1px solid themes.themed("secondary900");
                    }
                }
            }
        }

        .fattening-progress-text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-transform: uppercase;
            font-weight: 600;
        }
    }

    &-active-day-line,
    &-active-day-text {
        position: absolute;
    }

    &-active-day-text {
        white-space: nowrap;
        top: calc(100% - 1.1rem);
    }
}