@use "../../../resources/scss/utils/mixins";
@use "../../../resources/scss/themes/themes";

.view-container {
  padding: .75rem;
  display: flex;
  flex: 1;
  background: inherit;
  flex-direction: column;

  &.padding-for-buttons {
    padding-bottom: 6rem;
  }

  >* {
    width: 100%;
  }

  .no-role-component {
    margin: -.75rem;
    width: calc(100% + 1.5rem);
  }
}

@include mixins.breakpointWithHelper(themes.$xs) {
  .view-container {
    padding: .75rem .5rem;
  }
}