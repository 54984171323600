@use "../../../../resources/scss/themes/themes";
@use "../../../../resources/scss/utils/mixins";


@keyframes slideInFromLeft {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.fetura-button-group {
  display: flex;
  align-items: center;
  z-index: 998;

  .fetura-input {
    margin: 0 !important;
  }

  .fetura-bg-spacer {
    width: 1px;
    height: 2rem;

    @include themes.themify(themes.$themes) {
      background: themes.themed("background500");
    }
  }

  .fetura-bg-text {
    padding: themes.$paddingSmallVertical themes.$paddingSmallHorizontal themes.$paddingSmallVertical themes.$paddingSmallHorizontal;
    line-height: 1.618;
    @include mixins.noBreakWord();
  }

  .fetura-button {
    margin: 0 0 0 themes.$paddingSmallHorizontal !important;
  }

  &.fetura-info-box {
    animation: 5s ease-out 0s 1 slideInFromLeft;
  }

  &:not(.fixed) {

    .fetura-button,
    .fetura-info-box {
      &:not(.round) {
        margin: 0 !important;
        border-radius: 0;
      }

      &.bordered:after {
        border-radius: 0 !important;
        border-left-width: 1px !important;
        border-right-width: 1px !important;
      }
    }
  }

  &:not(.vertical) {
    .fetura-info-box {
      float: left;
      border-radius: themes.$radiusSmall;
      max-width: 350px;
      line-height: 1;
    }

    .fetura-button,
    .fetura-input {

      &:not(.round) {
        &:first-child {
          border-radius: themes.$radiusSmall 0 0 themes.$radiusSmall;

          &.bordered:after {
            border-left-width: 2px !important;
            border-radius: themes.$radiusSmall 0 0 themes.$radiusSmall !important;
          }

        }

        &:last-child {
          border-radius: 0 themes.$radiusSmall themes.$radiusSmall 0;

          &.bordered:after {
            border-radius: 0 themes.$radiusSmall themes.$radiusSmall 0 !important;
            border-right-width: 2px !important;
          }

        }
      }
    }

  }

  &.vertical {
    flex-direction: column;

    .fetura-button,
    .fetura-info-box {
      display: block;
      width: 100%;

      &:not(.round) {
        &:first-child {
          border-radius: themes.$radiusSmall themes.$radiusSmall 0 0;

          &.bordered:after {
            border-left-width: 2px !important;
            border-radius: themes.$radiusSmall 0 0 themes.$radiusSmall !important;
          }

        }

        &:last-child {
          border-radius: 0 0 themes.$radiusSmall themes.$radiusSmall;

          &.bordered:after {
            border-radius: 0 themes.$radiusSmall themes.$radiusSmall 0 !important;
            border-right-width: 2px !important;
          }

        }
      }
    }
  }

  &.fixed {
    position: fixed;
    right: themes.$paddingBigHorizontal;
    bottom: themes.$paddingBigVertical;

    .fetura-info-box {
      display: flex;
      align-items: center;
      border: none !important;
      @include mixins.shadow();
      height: 4rem !important;
      padding: themes.$paddingBigHorizontal !important;
      margin: 0 !important;
      position: relative;
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: -1.75rem;
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
        background: transparent;
        box-shadow: 2.5rem 0 0 0 inset;
        z-index: -1;
      }
    }

    .fetura-info-box:only-child {
      &:before {
        display: none;
      }
    }

    .fetura-button {
      z-index: 2;

      &.round {
        height: 4rem;
        width: 4rem;
        min-width: 4rem;
        min-height: 4rem;
        @include mixins.shadow();

        .icon-container {
          font-size: 2rem;
        }
      }
    }
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  margin: 0 0.25rem 1rem;

  &.show-enter-done {
    transition: bottom 300ms themes.$easeStandard;

    .fetura-button {
      opacity: 1;
      transition: opacity 200ms themes.$easeStandard;
    }
  }

  &.show-enter {
    bottom: -150%;
    transition: bottom 300ms themes.$easeStandard;

    .fetura-button {
      opacity: 0;
      transition: opacity 100ms themes.$easeStandard;
    }
  }

  &.show-exit {
    bottom: 0%;
    transition: bottom 300ms themes.$easeStandard;
    pointer-events: none;

    .fetura-button {
      opacity: 0;
      transition: opacity 100ms themes.$easeStandard;
    }
  }

  &.show-exit-active {
    bottom: -150%;
    transition: bottom 300ms themes.$easeStandard;

    .fetura-button {
      opacity: 0;
      transition: opacity 100ms themes.$easeStandard;
    }
  }

  .fas.fa-exclamation-circle {
    transform: scale(2, 2);
  }
}